import axios from 'axios'
import config from './config-client'
import TokenService from "./token.service";
import store from '../store'


axios.interceptors.request.use(
    config => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
let isRefreshing = false;

let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};



axios.interceptors.response.use(
    response => response,
    async (err) => {
        const originalConfig = err.config;
        const token = TokenService.getLocalRefreshToken();
        
        //lưu lại url trước khi login
        let currentURL = window.location.href;
        
        sessionStorage.setItem('urlBeforeLogin', currentURL);

        if (originalConfig.url !== config.baseURL + "/api/refresh" && err.response && token) {
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {

            if (isRefreshing) {
                return new Promise(function(resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then(token => {
                        originalConfig.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalConfig);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }

            originalConfig._retry = true;
            isRefreshing = true;

            return new Promise(function(resolve, reject) {
                axios
                    .post(config.baseURL + '/api/refresh', {
                        refresh_token: token})
                    .then(({ data }) => {
                        const { access_token, refresh_token} = data;
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
                        originalConfig.headers['Authorization'] = 'Bearer ' + access_token;
                        processQueue(null, access_token);

                        store.dispatch('authentications/refreshToken', {access_token, refresh_token});
                        TokenService.updateLocalAccessToken({ access_token, refresh_token });

                        resolve(axios(originalConfig));
                    })
                    .catch(err => {
                        processQueue(err, null);
                        store.dispatch('authentications/forceLogout');
                        console.log('Refresh fail');
                        reject(err);
                    })
                    .then(() => {
                        isRefreshing = false;
                    });
            });
          }
        }

        if (originalConfig.url !== config.baseURL + "/api/getToken") {
            if (err.response.status === 401) {
                console.log('401');

                const urlLogin =  process.env.VUE_APP_LOGIN_URL || 'https://stg-account-mcv.9pay.mobi';
                const url = urlLogin;
                window.location.href = url + '?error=Unauthorized';
                // store.dispatch('authentications/forceLogout');
            }
        }
  
        return Promise.reject(err);
      }
)

function checkStatus(response) {
    if (response && (response.status === 200 || response.status === 304)) {
        return response
    }
    return {
        data: {
            code: -404,
            message: response.statusText,
            data: ''
        }
    }
}

function checkCode(res) {
    store.dispatch('authentications/checkUserLogged');
    // if (res.data.code === -500) {
    //     window.location.href = '/backend'
    // } else if (res.data.code === -400) {
    //     window.location.href = '/'
    // } else if (res.data.code !== 200) {
    //     console.log(res.data.message)
    // }
    return res && res.data
}

export default {
    file(url, data, headers) {
        return axios({
            method: 'post',
            url,
            data,
            headers: headers
        })
            .then(checkStatus)
            .then(checkCode)
    },
    post(url, data, headers) {
        return axios({
            method: 'post',
            url: config.baseURL + config.api + url,
            data: data,
            timeout: config.timeout,
            headers: headers
        })
            .then(checkStatus)
            .then(checkCode)
    },
    get(url, params, headers) {
        return axios({
            method: 'get',
            url: config.baseURL + config.api + url,
            params,
            timeout: config.timeout,
            headers: headers
        })
            .then(checkStatus)
            .then(checkCode)
    }
}
