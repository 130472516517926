import UserAPI from '../../../services/user.service';
import CallApi from '../../../services/api.service';
import config from '../../../services/config';
import router from '../../../router';
import Vue from 'vue'
import VueCookies from "vue-cookies";
import helpers from "../../../until/helper";
var md5 = require('md5');

const login = async ({commit}, { username, password}) => {
    commit('LOGIN_REQUEST', {username});
    // api login called
    const user  = await UserAPI.login({ username: username, password });
    if (user.access_token) {
        const userInfo = {
            accessToken: user.access_token,
            refreshToken: user.refresh_token,
            ...user.info,
        }
        localStorage.setItem("user", JSON.stringify(userInfo));
        commit('LOGIN_SUCCESS', {userInfo});
        router.push('/');
    } else if(user.error == 'Unauthorized') {
        const urlLogin =  process.env.VUE_APP_LOGIN_URL || 'https://stg-account-mcv.9pay.mobi';
        const url = urlLogin;
        window.location.href = url;
    }else {
        router.push('login');
    }
  

}

const checkUserLogged = ({getters}) => {
    const userLogged = VueCookies.get('user_logged');
    const userLogin = getters.getInfoUserLogin;
    if (!userLogged || (userLogin && userLogged && userLogged != userLogin.username && userLogged != md5(userLogin.username))) {
        console.log('login lai');
        localStorage.removeItem("user");
        router.push('login');
    }
    // console.log(userLogged);
}


const loginSSO = async ( {commit}, {code ,state ,device}) => {
    if (!code || !state) {
        router.push({ name : 'login'});
    }
    const user  = await UserAPI.getToken({ code, state ,device });
    if (user.access_token) {
        const userInfo = {
            accessToken: user.access_token,
            refreshToken: user.refresh_token,
            clients: user.clients,
            ...user.info,
        }
        if (user.info && user.info.user && user.info.user.lang) {
            Vue.i18n.set(user.info.user.lang);
        }

        localStorage.setItem("user", JSON.stringify(userInfo));
        VueCookies.set('user_logged', user.info.user.username, null, null, process.env.VUE_APP_SUB_DOMAIN);
        commit('LOGIN_SUCCESS', {userInfo});

        let urlBeforeLogin = sessionStorage.getItem('urlBeforeLogin');
        if(helpers.isset(urlBeforeLogin)){

            if(!urlBeforeLogin.includes('account/security')) {
                window.location.href = urlBeforeLogin;
            } else {
                router.push('/');
            }
        }else{
            router.push('/');
        }
    } else {
        router.push('login');
    }
}

const logout = async () => {
    const logout = await UserAPI.logout();
    if (logout.status && logout.status == 'success') {
        localStorage.removeItem("user");
        sessionStorage.removeItem('urlBeforeLogin')
        const urlLogout = config.ssoUrl + '/logout?redirectUrl=' + location.protocol + '//' + location.host;
        window.location.href = urlLogout;
    }
}

const refreshToken = ({ commit} , accessToken) => {
    commit('REFRESH_TOKEN', accessToken)
}

const forceLogout = () => {
    localStorage.removeItem("user");
    router.push('login');
}

const editSubAccount = async (_, params) => {
    const request = await CallApi.editSubAccount(params);
    return request;
}

const createSubAccount = async ({ commit }, params) => {
    const request = await CallApi.createSubAccount(params);
    if (request.data) {
        commit('UPDATE_LIST_MEMBER', request.data);
    }
    return request;
}

const getListMember = async ({ commit }) => {
    const listMember = await CallApi.getListMember();
    if (listMember) {
        commit('GET_LIST_MEMBER', listMember);
    }
}

const changeLanguage = async ({ commit }, params) => {
    commit('CHANGE_LANGUAGE_SUCCESS', params.lang);
    const request = await CallApi.changeLanguage(params.lang);
    return request;
}

export default {
    login,
    logout,
    refreshToken,
    forceLogout,
    editSubAccount,
    createSubAccount,
    getListMember,
    changeLanguage,
    loginSSO,
    checkUserLogged
}
