<template>
    <div 
        id="g-recaptcha"
        class="g-recaptcha"
        :data-sitekey="sitekey">
    </div>
</template>

<script>

export default {
    name: "Recaptcha",
    props: {
        language:{
            type: String,
            default: null,
        },
    },
    data () {
        return {
            sitekey: process.env.VUE_APP_SITE_KEY_RE_CAPTCHA,
            // sitekey: '6LfCJn0qAAAAANq1oONWc45XmxEVo2DBm3oclHVx',
            widgetId: 0
        }
    },
    methods: {
        execute () {
            window.grecaptcha.execute(this.widgetId)
        },
        reset () {
            window.grecaptcha.reset(this.widgetId)
        },
        render () {
            if (window.grecaptcha && window.grecaptcha.render) {
                    if(this.widgetId) {
                        this.reset();
                    }
                    
                    this.widgetId = window.grecaptcha.render('g-recaptcha', {
                        sitekey: this.sitekey,
                        hl: this.language,
                    // the callback executed when the user solve the recaptcha
                    callback: (response) => {
                        // emit an event called verify with the response as payload
                        this.$emit('verify', response);
                    }
                });
            }
        },
    },
    mounted () {
        this.render();
    }
}
</script>