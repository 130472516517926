<template>
  <a-modal
      :title="!isEdit ? $t('Label.QRCreation') : $t('Label.QRUpdate')"
      v-model="visibleModal"
      :maskClosable="false"
      :closable="false"
      :destroyOnClose="false"
      wrapClassName="modal-va">
    <a-row>
      <a-col :xs="{ span: 24 }" :xl="{ span: 24 }">
        <a-form-model-item
            ref="localVaForm.BranchName"
            prop="localVaForm.BranchName"
        >
          <span slot="label">{{ $t('VirtualAccount.BranchName') }}</span>
          <a-input
              ref="BranchName"
              v-model="localVaForm.branchName"
              :placeholder="$t('VirtualAccount.BranchNameLabel')"
              @input="handleBranchNameKeyUp($event)"
              :disabled="isEdit"
              :class="[(isEdit ? 'disabled' : ''), (errors.branchNameError ? 'input-error' : '')]"
          >
          </a-input>
          <p class="text-danger mb-0" v-if="errors.branchNameError">{{ branchNameErrorMes }}</p>
        </a-form-model-item>
        <a-form-model-item
            ref="localVaForm.bankCode"
            prop="localVaForm.bankCode"
        >
          <span slot="label">{{ $t('VirtualAccount.Bank') }}</span>
          <a-select
              ref="dataVaForm.bankCode"
              v-model="localVaForm.bankCode"
              default-value=""
              class="w-100 store-qr-bank-code"
              :disabled="isEdit"
              :placeholder="$t('VirtualAccount.Placeholder.ChoosingBank')"
              show-search
          >
            <a-select-option value="">
              {{ $t('VirtualAccount.Placeholder.ChoosingBank') }}
            </a-select-option>
            <a-select-option
                v-for="(label, key) in banks"
                :key="key"
                :value="key"
            >
              {{ $t(label) }}
            </a-select-option>
          </a-select>
          <p class="text-danger mb-0" v-if="errors.bankCodeError">{{ $t('VirtualAccount.Validate.BankIsRequired')}}</p>
        </a-form-model-item>
        <a-form-model-item
            ref="dataVaForm.bankAccountName"
            prop="dataVaForm.bankAccountName"
        >
          <span slot="label">{{ $t('VirtualAccount.BankAccountName') }}</span>
          <a-input
              ref="dataVaForm.bankAccountName"
              v-model="upperCaseBankAccountName"
              @input="handleBankAccountNameKeyUp($event)"
              @blur="hanldleBankAccountName($event)"
              :class="errors.bankAccountNameError ? 'input-error' : ''"
              :placeholder="$t('VirtualAccount.BankAccountNameLabel')"
          >
          </a-input>
          <p class="text-danger mb-0" v-if="errors.bankAccountNameError">{{ bankAccountNameErrorMes }}</p>
        </a-form-model-item>
        <a-form-model-item
            class="visually-hidden"
            v-if="isEdit"
            ref="dataVaForm.bankAccountNo"
            prop="dataVaForm.bankAccountNo"
        >
          <a-input
              ref="dataVaForm.bankAccountNo"
              v-model="localVaForm.bankAccountNo"
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item
            ref="refund_type"
            prop="refund_type"
            class="mt-3"
        >
          <a-radio-group
              ref="refund_type"
              prop="refund_type"
              v-model="localRequestAmount"
              class="row"
              @change="onChangeRequestAmount"
          >
            <a-row class="p-0">
              <a-col :xs="{ span: 24 }" :xl="{ span: 12 }">
                <a-radio :value="false">{{ $t('VirtualAccount.AcceptAnyAmount')}}</a-radio>
              </a-col>
              <a-col :xs="{ span: 24 }" :xl="{ span: 12 }">
                <a-radio :value="true">{{ $t('VirtualAccount.CollectTheCorrectAmount')}}</a-radio>
              </a-col>
            </a-row>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
            v-if="localRequestAmount"
            ref="dataVaForm.requestAmount"
            prop="dataVaForm.requestAmount">
          <span slot="label">{{ $t('VirtualAccount.ModalRequestAmount') }}</span>
          <div class="form-item-with-transition">
            <a-input
                class="w-100"
                ref="dataVaForm.requestAmount"
                v-model="localVaForm.requestAmount"
                @keypress="onlyNumber($event)"
                @keyup="resetReqAmount($event)"
                :placeholder="$t('VirtualAccount.RequestAmount')"
                :class="errors.requestAmountError ? 'input-error' : ''"
                v-mask="currencyMask"
            >
            </a-input>
          </div>
          <p class="text-danger mb-0" v-if="errors.requestAmountError">{{ $t('VirtualAccount.Validate.RequestAmountIsRequired')}}</p>
        </a-form-model-item>
        <a-checkbox v-if="isEdit" v-model="localVaStatus" @change="changeStatus()" class="mt-3"><span>{{ $t('VirtualAccount.Active')}}</span></a-checkbox>
      </a-col>
    </a-row>
    <template #footer>
      <div>
        <a-button @click="closeModal">{{ $t('Button.Back') }}</a-button>
        <a-button
            type="primary" :disabled="!isSubmitButtonEnabled" @click="handleOk">
        {{ isEdit ? $t('Button.Edit') : $t('Button.Create') }}
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>

import createNumberMask from "text-mask-addons/dist/createNumberMask";
import helper from "@/until/helper";

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: "createQR",
  mounted() {},
  computed:{
    upperCaseBankAccountName: {
      get() {
        // let str = helper.removeVietnameseTones(this.localVaForm.bankAccountName).trim()
        return this.localVaForm.bankAccountName.toUpperCase();
      },
      set(value) {
        this.localVaForm.bankAccountName = value.toUpperCase();
      }
    },
    isSubmitButtonEnabled() {
      let basicConditions = true, additionalCondition = true;
      if(this.localVaForm.bankCode === ''
          || this.localVaForm.branchName === ''
          || this.localVaForm.bankAccountName === ''
          || this.errors.branchNameError
          || this.errors.bankAccountNameError){
        basicConditions = false;
      }

      if(this.localRequestAmount && (this.localVaForm.requestAmount === '' || this.localVaForm.requestAmount === null)){
        additionalCondition = false;
      }
      return basicConditions && additionalCondition;
    },
  },
  props: {
    clientId: {
      type: String,
      required: true
    },
    branchName: {
      type: String,
      required: null
    },
    bankCode: {
      type: String,
      required: true
    },
    bankAccountName: {
      type: String,
      required: null
    },
    requestAmount: {},
    status: {
      type: Number,
      default: 0
    },
    vaStatus:{
      type: Boolean,
      default: false
    },
    bankAccountNo: {
      type: String
    },
    visibleModal:{
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isRequestAmount: {
      type: Boolean,
      default: false
    },
    banks:{
      type: Object,
      required: null
    },
  },
  data(){
    return {
      currencyMask,
      localVaForm: {
        bankCode: this.bankCode,
        clientId: this.clientId,
        branchName: this.branchName,
        bankAccountName: this.bankAccountName,
        requestAmount: this.requestAmount,
        status: this.status
      },
      localVaStatus: this.vaStatus,
      errors: {
        bankCodeError: false,
        branchNameError: false,
        bankAccountNameError: false,
        requestAmountError: false,
      },
      branchNameErrorMes: '',
      bankAccountNameErrorMes: '',
      localRequestAmount: this.isRequestAmount,
      maxReqAmount: 500000000,
    }
  },
  methods:{
    isset: helper.isset,
    closeModal() {
      this.resetLocalData();
      this.$emit('close-modal');
    },
    handleBranchNameKeyUp(event){
      let inputValue = event.target.value;
      this.branchNameErrorMes = '';

      if(inputValue === ''){
        this.errors.branchNameError = true;
        this.branchNameErrorMes = this.$t('VirtualAccount.Validate.BranchNameIsRequired');
        return;
      }

      // if(this.inputContainsSpecialCharacters(inputValue)){
      //   this.errors.branchNameError = true;
      //   this.branchNameErrorMes = this.$t('VirtualAccount.Validate.BranchNameContainsSpecialCharacters');
      //   return;
      // }
      //
      // if(this.inputContainsVietNameseCharacters(inputValue)){
      //   this.errors.branchNameError = true;
      //   this.branchNameErrorMes = this.$t('VirtualAccount.Validate.BranchNameContainsVietNameseCharacters');
      //   return;
      // }

      if (inputValue.length > 200){
        this.errors.branchNameError = true;
        this.branchNameErrorMes = this.$t('VirtualAccount.Validate.BranchNameMaxLength');
        return;
      }
      this.errors.branchNameError = false;
    },
    inputContainsSpecialCharacters(str) {
      const regex = /[`!@#$%^&*()_+\-=\\|;:'"{},.<>?~[\]]/;
      return  regex.test(str);
    },
    inputContainsVietNameseCharacters(str){
      const regex = /[\u00C0-\u1EF9]/g;
      return regex.test(str);
    },
    handleBankAccountNameKeyUp(event){
      let inputValue = event.target.value;
      this.bankAccountNameErrorMes = '';
      if(inputValue === ''){
        this.errors.bankAccountNameError = true;
        this.bankAccountNameErrorMes = this.$t('VirtualAccount.Validate.BankAccountNameIsRequired');
        return;
      }

      if (inputValue.length > 32){
        this.errors.bankAccountNameError = true;
        this.bankAccountNameErrorMes = this.$t('VirtualAccount.Validate.BankAccountNameMaxLength');
        return;
      }

      if(this.inputContainsSpecialCharacters(inputValue)){
        this.errors.bankAccountNameError = true;
        this.bankAccountNameErrorMes = this.$t('VirtualAccount.Validate.BankAccountNameContainsSpecialCharacters');
        return;
      }

      // if(this.inputContainsVietNameseCharacters(inputValue)){
      //   this.errors.bankAccountNameError = true;
      //   this.bankAccountNameErrorMes = this.$t('VirtualAccount.Validate.BankAccountNameContainsVietNameseCharacters');
      //   return;
      // }

      this.errors.bankAccountNameError = false;
    },
    onChangeRequestAmount(){
      if(!this.localRequestAmount){
        this.localVaForm.requestAmount = '';
        this.errors.requestAmountError = false;
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      let inputValue = $event.target.value;

      if (inputValue === '0' && (keyCode >= 48 && keyCode <= 57)) {
        $event.preventDefault();
      }

      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    resetReqAmount($event){
      let val = parseFloat($event.target.value ? $event.target.value.replaceAll(',', '') : 0);
      if(val > this.maxReqAmount){
        this.localVaForm.requestAmount = this.showMoneyFormat(this.maxReqAmount);
      }
      if (val === 0) {
        val = '';
        $event.target.value = val;
        this.localVaForm.requestAmount = val;
      }

      if (this.localVaForm.requestAmount === ''){
        this.errors.requestAmountError = true;
      }else{
        this.errors.requestAmountError = false;
      }
    },
    validateVAForm(){
      if(this.localVaForm.bankCode === ''){
        this.errors.bankCodeError = true;
        return false;
      }
      if(this.localVaForm.branchName === ''){
        this.errors.branchNameError = true;
        return false;
      }
      if(this.localVaForm.bankAccountName === ''){
        this.errors.bankAccountNameError = true;
        return false;
      }
      if(this.localRequestAmount && (this.localVaForm.requestAmount === '' || this.localVaForm.requestAmount === null)){
        this.errors.requestAmountError = true;
        return false;
      }
      return true;
    },
    handleOk() {
      this.localVaForm.bankAccountName = helper.removeVietnameseTones(this.localVaForm.bankAccountName).trim().toUpperCase()
      this.validateVAForm();
      let newFormData = {
        bankCode: this.localVaForm.bankCode,
        clientId: this.localVaForm.clientId,
        branchName: this.localVaForm.branchName,
        bankAccountName: this.localVaForm.bankAccountName,
        requestAmount: this.localVaForm.requestAmount,
        status: this.localVaForm.status
      }

      this.resetLocalData();
      this.$emit('update-dataVaForm', newFormData);
    },
    resetError(){
      this.errors.bankCodeError = false;
      this.errors.branchNameError = false;
      this.errors.bankAccountNameError = false;
      this.errors.requestAmountError = false;
    },
    resetLocalData(){
      this.localVaForm.clientId = '';
      this.localVaForm.branchName = '';
      this.localVaForm.bankCode = '';
      this.localVaForm.bankAccountName = '';
      this.localVaForm.requestAmount = '';
      this.localRequestAmount = false;
      this.localVaForm.status = 0;
      this.localVaStatus = false;
    },
    test(){
      console.log(this.localVaForm)
      console.log(this.localRequestAmount)
      console.log(this.clientId)
      console.log(this.bankCode)
      console.log(this.requestAmount)
      console.log(this.isRequestAmount)
    },
    hanldleBankAccountName(event){
      let inputValue = event.target.value;
      this.localVaForm.bankAccountName = helper.removeVietnameseTones(inputValue).trim().toUpperCase();
    },
    changeStatus(){
      this.localVaForm.status = this.localVaForm.status === 1 ? 0 : 1;
    }
  },
  watch: {
    visibleModal(){
      this.resetError();
    },
    clientId(newVal) {
      this.localVaForm.clientId = newVal;
    },
    branchName(newVal) {
      this.localVaForm.branchName = newVal;
    },
    bankCode(newVal) {
      this.localVaForm.bankCode = newVal;
    },
    bankAccountName(newVal) {
      this.localVaForm.bankAccountName = newVal;
    },
    requestAmount(newVal) {
      this.localVaForm.requestAmount = newVal !== 0 && this.isset(newVal) ? newVal : null;
    },
    status(newVal) {
      this.localVaForm.status = newVal;
    },
    isRequestAmount(newVal){
      this.localRequestAmount = newVal
    },
    vaStatus(newVal){
      this.localVaStatus = newVal
    }
  },
}
</script>

<style type="text/css">
  .input-error{
    border-color: #f5222d !important;
  }
  .input-error:focus {
    outline: 0;
    box-shadow: 0 0 0 2px #ecbbbe !important;
  }
</style>