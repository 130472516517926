<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs">
      <a-button
        v-if="checkPermission(user, 'canAddInvoice') && !checkIsMasterMerchant(user) && checkMerchantActive(user)"
        type="primary"
        class="float-end mt-3 btn-create"
        @click="createInvoice"
        icon="plus"
      >
        {{ $t('Button.Add') }}
      </a-button>
    </PageTitle>

    <section class="section transaction-detail">
      <a-card class="mb-30 shadow">
        <a-row class="pb-0 pb-xl-3">
          <a-col :xs="{ span: 24 }" :xl="{ span: 8 }" class="pr-10 pl-10 mb-3 my-xl-2">
            <DateRangePicker
              :dateFrom.sync="dateFrom"
              :date-to.sync="dateTo"
              @onChangeDate="onChangeDate"
            ></DateRangePicker>
          </a-col>
          
          <a-col :xs="{ span: 24 }" :xl="{ span: 8 }" class="pr-10 pl-10 mb-3 my-xl-2">
            <a-input
              ref="titleInvoice"
              v-model="titleInvoice"
              :placeholder="$t('Invoice.Title')"
              v-on:keyup.enter="search"
            >
              <a-icon slot="suffix" type="search" />
            </a-input>
          </a-col>

          <a-col :xs="{ span: 24 }" :xl="{ span: 8 }" class="pr-10 pl-10 mb-3 my-xl-2">
            <a-select
              ref="isActive"
              v-model="isActive"
              default-value=""
              style="width: 100%"
            >
              <a-icon slot="suffixIcon" type="file-protect" />
              <a-select-option value="">{{ $t('Invoice.StatusInvoice') }}</a-select-option>
              <a-select-option v-for="(item, id) in AppConstant.ACTIVE_STATUS" :key="id" :value="id">
                {{ $t(item) }}
              </a-select-option>
            </a-select>
          </a-col>

          <a-col :xs="{ span: 24 }" :xl="{ span: 8 }" class="pr-10 pl-10 mb-3 my-xl-2">
            <a-select
              ref="status"
              v-model="status"
              default-value=""
              style="width: 100%"
            >
              <a-icon slot="suffixIcon" type="file-protect" />
              <a-select-option value="">{{ $t('Invoice.PaymentStatus') }}</a-select-option>
              <a-select-option
                v-for="(statusDisplay, id) in showStatusInvoiceList()"
                :key="id"
                :value="id"
              >
                {{ $t(statusDisplay) }}
              </a-select-option>
            </a-select>
          </a-col>
          
          <a-col :xs="{ span: 24 }" :xl="{ span: 8 }" class="pr-10 pl-10 mb-3 my-xl-2">
            <a-input
              ref="invoiceCode"
              v-model="invoiceCode"
              :placeholder="$t('Invoice.InvoiceCode')"
              v-on:keyup.enter="search"
            >
              <a-icon slot="suffix" type="file-protect" />
            </a-input>
          </a-col>

          <a-col :xs="{ span: 24 }" :xl="{ span: 8 }" class="pr-10 pl-10 mb-3 my-xl-2">
            <a-select
              ref="paymentTimes"
              v-model="paymentTimes"
              default-value=""
              style="width: 100%"
            >
              <a-icon slot="suffixIcon" type="file-protect" />
              <a-select-option value="">{{ $t('Label.PurchaseNumber') }}</a-select-option>
              <a-select-option v-for="(item, id) in showInvoicePaymentTimeList()" :key="id" :value="id">
                {{ $t(item) }}
              </a-select-option>
            </a-select>
          </a-col>

          <a-col
            :xs="{ span: 24 }"
            :xl="{ span: 8 }"
            class="pr-10 pl-10 mb-3 my-xl-2"
            v-if="isNaN(merchantsDataRole)">
            <a-select
              style="width: 100%"
              @change="selectMerchant"
              v-model="merchantFilterDisplay"
              default-value="">
              <a-select-option value="">
                {{ $t('Dashboard.AllMerchant') }}
              </a-select-option>
              <a-select-option :value="id" v-for="(merchant, id, index) in merchantsDataRole" :key="index">
                {{ merchant.name }}
              </a-select-option>
            </a-select>
          </a-col>

          <a-col :xs="{ span: 24 }" :xl="{ span: 8 }" class="pr-10 pl-10 mb-3 my-xl-2" v-if="user && (user.type != UserTypeConstant.MASTER_MERCHANT)">
            <a-select
              ref="profileId"
              v-model="profileId"
              default-value=""
              class="w-100"
              :placeholder="getPlaceholderText()"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option value="">
                {{ $t(InvoiceStatusConstant.FILTER_OF_PROFILE_PLACEHOLDER) }}
              </a-select-option>
              <a-select-option
                  v-for="(profile) in profileOptions"
                  :key="profile.id"
                  :value="profile.id"
              >
                {{ $t(profile.label) }}
              </a-select-option>
            </a-select>
          </a-col>

          <a-col :xs="{ span: 24 }" :xl="{ span: 8 }" class="pr-10 pl-10 mb-3 my-xl-2" v-if="user && (user.type != UserTypeConstant.MASTER_MERCHANT)">
            <a-select
                ref="createdBy"
                v-model="createdBy"
                default-value=""
                class="w-100"
                show-search
                :filter-option="filterOptionCreatedBy">
              <a-select-option value="">
                {{ $t('Transaction.SelectCreator') }}
              </a-select-option>
              <a-select-option
                  v-for="(member, index) in members"
                  :key="index"
                  :value="member"
              >
                {{ $t(member) }}
              </a-select-option>
            </a-select>
          </a-col>
          
          <a-col :xs="{ span: 24 }" :xl="{ span: 8 }" class="pr-10 pl-10 mb-3 my-xl-2 d-flex d-xl-block justify-content-center">
            <a-button type="primary" class="me-2" @click="search">
              {{ $t('Button.Search') }}
            </a-button>
            <a-button type="secondary" @click="clear">{{ $t('Button.Cancel') }}</a-button>
          </a-col>
        </a-row>
      </a-card>
      <a-spin :spinning="this.loading" size="large">
        <a-card class="mb-30 shadow">
          <a-row>
            <a-col :xs="{ span: 24 }" :xl="{ span: 15 }" class="pr-10 pl-10 mb-3 my-xl-2">
              <a-dropdown style="width: 300px; display: flex; justify-content: space-between; align-items: center;" v-model="visibleColumn">
                <template #overlay>
                  <a-menu>
                    <a-menu-item class="all" @change="selectAll">
                      <a-checkbox v-model="allSelected" value="all">{{ $t('Transaction.SelectAll') }}</a-checkbox>
                    </a-menu-item>
                    <a-menu-item :key="id" v-for="(column, id) in InvoiceColumnConstant.INVOICE_COLUMN" :class="column.class" @change="handleChooseColumn(column.class)">
                      <a-checkbox :checked="selected.includes(column.class)" :value="column.class">{{ $t(column.title) }}</a-checkbox>
                    </a-menu-item>
                  </a-menu>
                </template>
                <a-button @click="handleOpenMenu">
                  {{ $t('Transaction.ChooseColumn') }} ({{ selected.length }})
                  <i class="bi bi-chevron-down ms-auto"></i>
                </a-button>
              </a-dropdown>
            </a-col>
          </a-row>
          <a-table
            :data-source="invoices"
            :columns="columns"
            :row-key="record => record.id"
            :pagination="false"
            class="mb-30 nowrap invoice-table"
            :scroll="{x: 'auto'}"
          >
            <template #customTitleNo>
              <div class="text-end">{{ $t('Invoice.No') }}</div>
            </template>
            <template slot="no" slot-scope="text, record, index">
              <div class="text-end" style="font-weight: bold">{{ (currentPage - 1) * perPage + index + 1 }}</div>
            </template>

            <template #customTitleUpdatedAt>
              <div class="text-nowrap">{{ $t('Invoice.UpdateDate') }}</div>
            </template>
            <template slot="updated_at" slot-scope="text, record">
              {{ formatDate(record.updated_at) }}
            </template>

            <template #customTitleMerchant v-if="isNaN(merchantsDataRole)">
              <div class="merchant-name text-nowrap">
                <p align="center" class="mb-0">{{ $t('Transaction.MerchantName') }}</p>
              </div>
            </template>
            <template slot="merchant_id" slot-scope="text, record" v-if="isNaN(merchantsDataRole)">
              <p class="mb-0">{{ merchantsDataRole[record.merchant_id].name }}</p>
            </template>

            <template #customTitleInvoiceNo>
              {{ $t('Invoice.InvoiceCode') }}
            </template>

            <template #customTitleInvoice>
              <div class="title">
                <p class="mb-0">{{ $t('Invoice.Title') }}</p>
              </div>
            </template>
            <template slot="titleInvoice" slot-scope="text, record">
              <div class="text-break">{{ record.title }}</div>
            </template>

            <template #customTitleAmount>
              <div class="text-nowrap text-end">{{ $t('Invoice.AmountMpos') }}</div>
            </template>
            <template slot="amount" slot-scope="text, record">
              <div class="text-end">{{ showMoneyFormat(record.amount) }} {{ record.currency }}</div>
            </template>

            <template #customTitleProfile>
              <div class="text-nowrap text-start">Profile</div>
            </template>
            <template slot="profile" slot-scope="text, record">
              <div class="text-start">{{ record.profile_id > 0 ? profileInfo[record.profile_id] : '--' }}</div>
            </template>

            <template #customTitleFeePayer>
              <div class="text-nowrap text-center">{{ $t('Label.FeePayerTitle') }}</div>
            </template>

            <template slot="fee_payer" slot-scope="text, record">
              <div class="text-center">{{ $t(showFeePayer(record.setting)) }}</div>
            </template>

            <template #customTitlePaymentTime>
              <div class="text-nowrap text-center">{{ $t('Label.PurchaseNumber') }}</div>
            </template>
            <template slot="payment_times" slot-scope="text, record">
              <div class="text-center">{{ $t(showPaymentTime(record.payment_times)) }}</div>
            </template>

            <template #customTitleCreatedBy>
              <div class="text-nowrap">{{ $t('Label.CreatedBy') }}</div>
            </template>
            <template slot="created_by" slot-scope="text, record">
              {{ $t(record.created_by != 0 ? record.created_by : '--') }}
            </template>
            
            <template #customTitleStatus>
              <div class="text-nowrap text-center">
                {{ $t('Invoice.PaymentStatus') }}
              </div>
            </template>
            <template slot="status" slot-scope="text, record">
              <div :class="'text-center fw-600 ' + (record.status == 2 ? 'text-warning' : (record.status == 3 ? 'c_text-success' : ''))">
                {{ $t(showStatusInvoice(record.status)) }}
              </div>
            </template>

            <template #customTitleIsActive>
              <div class="text-nowrap text-center">
                {{ $t('Invoice.StatusInvoice') }}
              </div>
            </template>
            <template slot="is_active" slot-scope="text, record">
              <div :class="'text-center fw-600 ' + (record.is_active == 1 ? 'c_text-success' : 'text-danger')">
                {{ $t(showIsactiveInvoice(record.is_active)) }}
              </div>
            </template>

            <template #customTitleLink>
              <div class="text-nowrap">{{ $t('Label.PaymentLink') }}</div>
            </template>
            <template slot="link" slot-scope="text, record">
              <a-tooltip>
                <template slot="title">
                  <span> {{ record.pay_link }}</span>
                </template>
                <a-button type="primary" size="small" @click="copyUrl(record.pay_link)">{{ $t('Button.Copy') }}</a-button>
              </a-tooltip>
            </template>


            <template #customTitleAction>
              <div class="invoice-action">
                {{ $t('Invoice.Action') }}
              </div>
            </template>
            <template slot="action" slot-scope="text, record">

              <router-link :to=" (!checkIsMasterMerchant(user) && checkPermission(user, 'canEditInvoice') && checkMerchantActive(user)) ? {name: 'EditInvoice', params: {id: record.id}} : {name: 'ViewInvoice', params: {id: record.id}}"  v-if="checkPermission(user, 'canEditInvoice')"  class="me-2">
                <a-tooltip>
                  <template slot="title">
                    <span> {{ $t('Tooltip.View') }}</span>
                  </template>
                  <a><a-icon slot="prefix" type="form" /></a>
                </a-tooltip>
              </router-link>

              <router-link
                :to="{name: 'ListTransaction', query: {order_code: record.code, date_from: dateFrom, date_to: dateTo}}"
                class="me-2" v-if="checkPermission(user, 'canViewListTransaction') && record.status !== UNPAID"
              >
                <a-tooltip>
                  <template slot="title">
                    <span> {{ $t('Tooltip.ViewTransaction') }}</span>
                  </template>
                  <a><a-icon slot="prefix" type="eye"/></a>
                </a-tooltip>
              </router-link>

              <router-link
                :to="{name: 'CreateInvoice', query: {id: record.id}}"
                class="me-2"
                v-if="!checkIsMasterMerchant(user)
                  && checkPermission(user, 'canAddInvoice')
                  && checkMerchantActive(user)
                  && !record.is_mpos"
              >
                <a-tooltip>
                  <template slot="title">
                    <span> {{ $t('Tooltip.Duplicate') }}</span>
                  </template>
                  <a><a-icon slot="prefix" type="snippets"/></a>
                </a-tooltip>
              </router-link>
            
              <a-tooltip class="me-2" v-if="checkPermission(user, 'canDeleteInvoice') && !checkIsMasterMerchant(user) && checkMerchantActive(user) && record.status === UNPAID">
                <template slot="title">
                  <span> {{ $t('Tooltip.Delete') }}</span>
                </template>
                <a href="javascript:void(0);" @click="showModal(record.id)">
                  <a-icon slot="prefix" type="delete"/>
                </a>
              </a-tooltip>

              <a-tooltip class="me-2" v-if="checkPermission(user, 'canEditInvoice') && !checkIsMasterMerchant(user) && checkMerchantActive(user) && !record.is_active">
                <template slot="title">
                  <span> {{ $t('Tooltip.Active') }}</span>
                </template>
                <a href="javascript:void(0);" @click="showModal(record.id, 'ACTIVE')">
                  <a-icon slot="prefix" type="check-square" theme="twoTone" two-tone-color="#52c41a"/>
                </a>
              </a-tooltip>

              <a-tooltip v-if="checkPermission(user, 'canEditInvoice') && !checkIsMasterMerchant(user) && checkMerchantActive(user) && record.is_active">
                <template slot="title">
                  <span> {{ $t('Tooltip.Inactive') }}</span>
                </template>
                <a href="javascript:void(0);" @click="showModal(record.id, 'INACTIVE')">
                  <a-icon slot="prefix" type="close-square" theme="twoTone" two-tone-color="#FF4040"/>
                </a>
              </a-tooltip>
            </template>
          </a-table>

          <a-row>
            <a-col :span="8">
              {{ $t('ShowPaginateFooterInvoice', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow}) }}
            </a-col>
            <a-col :span="16" align="end">
              <a-pagination
                v-model="currentPage"
                :current="currentPage"
                :page-size="perPage"
                :total="totalRow"
              />
            </a-col>
          </a-row>
        </a-card>
      </a-spin>

      <a-modal
        :title="$t('Notice.Title.Confirm')"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        :cancelText="$t('Button.Cancel')"
        :okText="$t('Button.OK')"
      >
        <p>{{ content }}</p>
      </a-modal>
    </section>
  </AppMasterVue>
</template>

<script>

import AppMasterVue from '../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import DateRangePicker from '@/components/DateRangePicker'
import { mapGetters } from 'vuex';
import helper from "../../until/helper";
import moment from "moment";
import InvoiceStatusConstant from "../../constants/InvoiceStatus";
import AppConstant from "../../constants/App";
import ProfileStatusConstant from "../../constants/ProfileStatus";
import TransactionColumnConstant from "@/constants/Transaction";
import InvoiceColumnConstant from "@/constants/Invoice";
import UserTypeConstant from "@/constants/UserType";

export default {
  name: 'InvoiceList',
  components: {
    AppMasterVue,
    PageTitle,
    DateRangePicker
  },
  data() {
    return {
      loading: false,
      UserTypeConstant: UserTypeConstant,
      title: 'Sidebar.InvoiceList',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Sidebar.InvoiceManagement',
          routeName: null,
          active: false,
        },
        {
          name: 'Sidebar.InvoiceList',
          routeName: 'InvoiceList',
          active: true
        }
      ],
      AppConstant: AppConstant,
      UNPAID: InvoiceStatusConstant.UNPAID,
      InvoiceStatusConstant: InvoiceStatusConstant,
      InvoiceColumnConstant: InvoiceColumnConstant,
      currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
      titleInvoice: this.isset(this.$route.query.title) ? this.$route.query.title : "",
      userId: this.isset(this.$route.query.updated_by) ? this.$route.query.updated_by : "",
      status: this.isset(this.$route.query.status) ? this.$route.query.status : "",
      isActive: this.isset(this.$route.query.is_active) ? this.$route.query.is_active : "1",
      paymentTimes: this.isset(this.$route.query.payment_times) ? this.$route.query.payment_times : "",
      invoiceCode: this.isset(this.$route.query.invoice_code) ? this.$route.query.invoice_code : "",
      dateFrom: this.isset(this.$route.query.date_from) ? this.$route.query.date_from : moment().startOf("month").format("DD/MM/YYYY"),
      dateTo: this.isset(this.$route.query.date_to) ? this.$route.query.date_to : moment().format("DD/MM/YYYY"),
      profileId: this.isset(this.$route.query.profile_id) ? this.$route.query.profile_id : "",
      visible: false,
      visibleColumn: false,
      confirmLoading: false,
      invoiceId: null,
      createdBy: this.isset(this.$route.query.created_by) ? this.$route.query.created_by : "",
      columns: [
        {
          slots: { title: "customTitleNo" },
          key: "no",
          scopedSlots: { customRender: "no" },
        },
        {
          key: "updated_at",
          slots: { title: "customTitleUpdatedAt" },
          scopedSlots: { customRender: "updated_at" },
        },
        {
          key: "merchant_id",
          slots: { title: "customTitleMerchant" },
          scopedSlots: { customRender: "merchant_id" },
        },
        {
          key: "invoice_no",
          dataIndex: "invoice_no",
          slots: { title: "customTitleInvoiceNo" },
        },
        {
          key: "title",
          slots: { title: "customTitleInvoice" },
          scopedSlots: { customRender: "titleInvoice" },
        },
        {
          key: "amount",
          slots: { title: "customTitleAmount" },
          scopedSlots: { customRender: "amount" },
        },
        {
          key: "profile",
          slots: { title: "customTitleProfile" },
          scopedSlots: { customRender: "profile" },
          align: "center",
          class: 'hide'
        },
        {
          key: "fee_payer",
          slots: { title: "customTitleFeePayer" },
          scopedSlots: { customRender: "fee_payer" },
          class: 'hide'
        },
        {
          key: "payment_times",
          slots: { title: "customTitlePaymentTime" },
          scopedSlots: { customRender: "payment_times" },
          class: 'hide'
        },
        {
          key: "created_by",
          slots: { title: "customTitleCreatedBy" },
          scopedSlots: { customRender: "created_by" },
          align: "center"
        },
        {
          key: "status",
          slots: { title: "customTitleStatus" },
          scopedSlots: { customRender: "status" },
        },
        {
          key: "is_active",
          slots: { title: "customTitleIsActive" },
          scopedSlots: { customRender: "is_active" },
          class: 'hide'
        },
        {
          key: "link",
          slots: { title: "customTitleLink" },
          scopedSlots: { customRender: "link" },
        },
        {
          key: "action",
          slots: { title: "customTitleAction" },
          scopedSlots: { customRender: "action" },
        },
      ],
      merchantFilter: this.isset(this.$route.query.merchant_id) ? this.$route.query.merchant_id : [],
      merchantFilterDisplay: (this.isset(this.$route.query.merchant_id) && !isNaN(this.$route.query.merchant_id)) ? this.$route.query.merchant_id : "",
      userUpdate: [],
      searchInput: [],
      searchInputOld: [],
      content: '',
      isDelete: false,
      updateActive: true,
      ProfileStatusConstant: ProfileStatusConstant,
      profileInfo: [],
      profileActive: {},
      profileOptions: [],
      createdByOptions: [],
      selected: ['updated_at', 'invoice_no', 'created_by'],
      allSelected: false,
    }
  },
  computed: {
    ...mapGetters({
      invoices: 'invoice/getInvoices',
      totalRow: 'invoice/getTotalRow',
      perPage: 'invoice/getPerPage',
      user: 'authentications/getUserLogin',
      merchantsDataRole: 'authentications/getMerchants',
      members: "authentications/getMembers",
    }),
  },
  mounted() {
    if (isNaN(this.merchantsDataRole)) {
      this.merchantFilter = [];
      Object.keys(this.merchantsDataRole).forEach(merchantId => {
        this.merchantFilter.push(parseInt(merchantId));
      });
      this.$store.dispatch("invoice/getListUserUpdate", {merchant_id: this.merchantFilter}).then(response => {
        if (response.data) {
          this.userUpdate = response.data;
        }
      });
    } else {
      this.merchantFilter = parseInt(this.merchantsDataRole);
    }

    // set data
    this.searchInput = {
      page: this.currentPage,
      date_from: this.dateFrom,
      date_to: this.dateTo,
      invoice_code: this.invoiceCode,
      status: this.status,
      title: this.titleInvoice,
      updated_by: this.userId,
      merchant_id: this.merchantFilter,
      is_active: this.isActive,
      payment_times: this.paymentTimes,
      profile_id: this.profileId,
      created_by: this.createdBy,
    };
    
    this.callApiInvoiceList();
    // this.callApiProfileList();
    this.callApiProfileInfo();
    this.convertMembersToArray()
  },
  methods: {
    formatDate: helper.formatDate,
    showMoneyFormat: helper.showMoneyFormat,
    isset: helper.isset,
    showStatusInvoice: helper.showStatusInvoice,
    showStatusInvoiceList: helper.showStatusInvoiceList,
    checkPermission: helper.checkPermission,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    checkMerchantActive: helper.checkMerchantActive,
    getCurrentTotalRow: helper.getCurrentTotalRow,
    showPaymentTime: helper.showPaymentTime,
    showIsactiveInvoice: helper.showIsactiveInvoice,
    showInvoicePaymentTimeList: helper.showInvoicePaymentTimeList,
    showFeePayer: helper.showFeePayer,
    selectMerchant(e) {
      if (e) {
        this.merchantFilter = parseInt(e);
      } else {
        this.merchantFilter = [];
        Object.keys(this.merchantsDataRole).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
        });
      }
    },
    handleLoading: function(){
      this.loading = !this.loading;
    },
    search() {
      this.searchInput = {
        page: 1,
        date_from: this.dateFrom,
        date_to: this.dateTo,
        invoice_code: this.invoiceCode,
        status: this.status,
        title: this.titleInvoice,
        updated_by: this.userId,
        merchant_id: this.merchantFilter,
        is_active: this.isActive,
        payment_times: this.paymentTimes,
        profile_id: this.profileId,
        created_by: this.createdBy
      };

      this.setRouter();
      this.callApiInvoiceList();
      // this.callApiProfileList();
      this.callApiProfileInfo();
    },
    clear() {
      this.titleInvoice = "";
      this.userId = "";
      this.status = "";
      this.invoiceCode = "";
      this.dateFrom = moment().startOf("month").format("DD/MM/YYYY");
      this.dateTo = moment().format("DD/MM/YYYY");
      this.currentPage = 1;
      this.merchantFilterDisplay = "";
      this.merchantFilter = [];
      this.isActive = "1";
      this.paymentTimes = "";
      this.profileId = "";
      this.createdBy = "";

      if (isNaN(this.merchantsDataRole)) {
        this.merchantFilter = [];
        Object.keys(this.merchantsDataRole).forEach(merchantId => {
          this.merchantFilter.push(parseInt(merchantId));
        });
      } else {
        this.merchantFilter = parseInt(this.merchantsDataRole);
      }

      this.search();
    },
    onChangeDate(value) {
      this.dateFrom = value.dateFrom;
      this.dateTo = value.dateTo;
    },
    showModal(invoiceId, action = 'DELETE') {
      if (action === 'DELETE') {
        this.isDelete = true;
        this.content = this.$t('Notice.Message.ConfirmDeleteInvoice');
      } else if (action === 'ACTIVE') {
        this.isDelete = false;
        this.updateActive = true;
        this.content = this.$t('Notice.Message.ConfirmActiveInvoice');
      } else {
        this.isDelete = false;
        this.updateActive = false;
        this.content = this.$t('Notice.Message.ConfirmInactiveInvoice');
      }
      this.visible = true;
      this.invoiceId = invoiceId;
    },
    handleOk() {
      this.confirmLoading = true;
      if (this.isDelete) {
        this.deleteInvoice(this.invoiceId);
      } else {
        this.activeInvoice(this.invoiceId, this.updateActive);
      }
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
        this.invoiceId = null;
      }, 1000);
    },
    handleCancel() {
      this.visible = false;
      this.invoiceId = null;
    },
    deleteInvoice(invoiceId) {
      this.$store.dispatch("invoice/deleteInvoice", { invoiceId: invoiceId }).then(response => {
        if (response.code === 0) {
          this.showNotice(
            'success',
            this.$t('Notice.Message.DeleteInvoiceSuccess'),
            ''
          );
        } else {
          this.showNotice(
            'error',
            this.$t('Notice.Message.DeleteInvoiceFail'),
            ''
          );
        }
      });
    },
    activeInvoice(invoiceId, active) {
      this.$store.dispatch("invoice/activeInvoice", { invoiceId: invoiceId, active: active }).then(response => {
        if (response.code === 0) {
          this.showNotice(
            'success',
            this.$t('Notice.Message.UpdateStatusInvoiceSuccess'),
            ''
          );
          this.callApiInvoiceList();
        } else {
          this.showNotice(
            'error',
            this.$t('Notice.Message.UpdateInvoiceFail'),
            ''
          );
        }
      });
    },
    async callApiInvoiceList() {
      this.handleLoading();
      await this.$store.dispatch("invoice/fetchInvoices", this.searchInput);
      this.handleLoading();
    },
    setRouter() {
      this.$router.replace({
        name: "InvoiceList",
        query: this.searchInput,
      }).catch(() => {});
    },
    createInvoice() {
      this.$router.push({name: 'CreateInvoice'});
    },
    copyUrl(payLink) {
      navigator.clipboard.writeText(payLink);
      this.showNotice(
        'success',
        this.$t('Notice.Title.Confirm'),
        this.$t('Notice.Message.CopySuccess')
      );
    },
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
    // callApiProfileList() {
    //   var searchInput = {
    //     status: ProfileStatusConstant.APPROVED,
    //     merchant_id: this.merchantFilter
    //   };
    //   this.$store.dispatch("profile/fetchProfileList", searchInput);
    // },
    callApiProfileInfo() {
      this.$store.dispatch("invoice/getProfileInfo", { merchant_id: this.merchantFilter }).then(response => {
        if (response.data) {
          let data = response.data;
          let dataProfileInfo = data.profiles[this.merchantFilter];

          dataProfileInfo.forEach((value) => {
            this.profileInfo[value.id] = value.name;

            if(value.status === 1) {
              this.profileActive[value.id] = value.name;
            }
          });

          this.convertProfileActiveToArray();
        }
      });
    },
    getPlaceholderText() {
      return this.$t(TransactionColumnConstant.FILTER_OF_PROFILE_PLACEHOLDER);
    },
    filterOption(input, option) {
      const profile = this.profileOptions.find((item) => item.id === option.key);
      if (profile) {
        return profile.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }
      return false;
    },
    convertProfileActiveToArray() {
      this.profileOptions = Object.keys(this.profileActive).map((id) => ({
        id: id,
        label: this.profileActive[id],
      }));
    },
    filterOptionCreatedBy(input, option){
      const createdBy = this.createdByOptions.find((item) => item.id === option.key);
      if (createdBy) {
        return createdBy.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }
      return false;
    },
    convertMembersToArray() {
      this.createdByOptions = Object.keys(this.members).map((id) => ({
        id: id,
        label: this.members[id],
      }));
    },
    handleChooseColumn: function(valueCheck) {
      if(this.selected.includes(valueCheck)) {
        let arr = this.selected.filter(function(item) {
            return item !== valueCheck;
        })
        this.selected = arr;
      } else {
        this.selected.push(valueCheck);
      }
      this.allSelected = false;
    },
    handleOpenMenu: function() {
      this.visibleColumn = true;
    },
    selectAll: function(event) {
      let classes = [];
      if(event.target.checked) {

        Object.values(this.InvoiceColumnConstant.INVOICE_COLUMN).forEach(vCol => {
          classes.push(vCol.class);
        });
      } 
      this.selected = classes;
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.searchInput = {
          page: value,
          date_from: this.dateFrom,
          date_to: this.dateTo,
          invoice_code: this.invoiceCode,
          status: this.status,
          title: this.titleInvoice,
          updated_by: this.userId,
          merchant_id: this.merchantFilter,
          is_active: this.isActive,
          payment_times: this.paymentTimes,
          profile_id: this.profileId,
          created_by: this.createdBy
        };
        this.setRouter();
        this.callApiInvoiceList();
        // this.callApiProfileList();
        this.callApiProfileInfo();
      },
    },
    selected: {
      handler: function (value) {
        if(value.length == 7) {
          this.allSelected = true;
        }
        let columnHide = [];
        Object.values(this.columns).forEach(vCol => {

          if(value.includes(vCol.key)) {
            vCol.class = '';
          } else {
            columnHide.push(vCol.key);

            Object.values(this.InvoiceColumnConstant.INVOICE_COLUMN).forEach(vTrans => {
              if(vCol.key === vTrans.class) {
                vCol.class = 'hide';
              }
            });
          }
        });
      }
    }
  },
}
</script>

<style>
.search-form .row {
  padding: 20px;
}
.search-form .row:first-child {
  padding-bottom: 0;
}
.table-footer {
  padding-top: 20px;
}
.btn-create.ant-btn{
  background: #2ebf0a;
  color: #FFFFFF;
  border: 0;
  font-weight: 600;
}
.btn-create span {
  font-size: 16px;
}
.merchant-name {
  min-width: 100px;
}
.title {
  min-width: 150px;
}
.invoice-amount {
  min-width: 130px;
}
.refund-time {
  min-width: 130px;
}
.invoice-action {
  min-width: 80px;
}
.invoice-table tbody tr td:last-child {
  line-height: 10px;
}
.fw-600 {
  font-weight: 600;
}
.c_text-success {
  color: #2ebf0a;
}
</style>
