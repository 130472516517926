<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs">
      <a-button
          class="float-end btn-create mt-3"
          type="primary"
          icon="plus"
          v-if="checkPermission(user, 'canAddQR') && isMerchant"
          @click="showModalCreate"
      >
        {{ $t('Button.Add') }}
      </a-button>
    </PageTitle>
    <a-card class="mb-30 shadow">
      <a-row class="pb-2">
<!--        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">-->
<!--          <DateRangePicker-->
<!--              :dateFrom.sync="searchInput.date_from"-->
<!--              :date-to.sync="searchInput.date_to"-->
<!--              @onChangeDate="onChangeDate"-->
<!--          ></DateRangePicker>-->
<!--        </a-col>-->
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-select
              ref="type"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              v-model="searchInput.virtual_account_id"
              default-value=""
              style="width: 100%"
          >
            <a-icon slot="suffixIcon" type="menu" />
            <a-select-option value="">{{ $t('QR.TitleSearch') }}</a-select-option>
            <a-select-option
                v-for="acc in list_virtual_account"
                :key="acc.id"
                :value="acc.id"
            >
              {{ acc.name }}
            </a-select-option>
          </a-select>
        </a-col>

<!--        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-select
              ref="bank_code"
              v-model="searchInput.bank_code"
              default-value=""
              class="w-100"
              :placeholder="$t('VirtualAccount.Bank')"
              show-search
          >
            <a-select-option value="">
              {{ $t('VirtualAccount.All') }}
            </a-select-option>
            <a-select-option
                v-for="(label, key) in bankForFilter"
                :key="key"
                :value="key"
            >
              {{ $t(label) }}
            </a-select-option>
          </a-select>
        </a-col>-->

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-input
              ref="orderCode"
              v-model="searchInput.bank_account_no"
              :placeholder="$t('VirtualAccount.BankAccountNo')"
          >
            <a-icon slot="suffix" type="search" />
          </a-input>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-input
              ref="orderCode"
              v-model="searchInput.bank_account_name"
              :placeholder="$t('VirtualAccount.BankAccountName')"
          >
            <a-icon slot="suffix" type="search" />
          </a-input>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-select
              ref="status"
              v-model="searchInput.status"
              default-value=""
              style="width: 100%"
          >
            <a-icon slot="suffixIcon" type="file-protect" />
            <a-select-option value="">{{ $t('VirtualAccount.All') }}</a-select-option>
            <a-select-option
                v-for="(item, id) in getAllVAStatus()"
                :key="id"
                :value="item.value"
            >
              {{ $t(item.text) }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col
            :xs="{ span: 24 }"
            :xl="{ span: 6 }"
            class="pr-10 pl-10 mb-3 my-xl-2"
            v-if="isNaN(merchants)">
          <a-select
              style="width: 100%"
              @change="selectMerchant"
              default-value="">
            <a-select-option value="">
              {{ $t('Dashboard.AllMerchant') }}
            </a-select-option>
            <a-select-option :value="id" v-for="(merchant, id, index) in merchants" :key="index">
              {{ merchant.name }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2 d-flex d-xl-block justify-content-center">
          <div>
            <a-button type="primary" class="me-2" @click="search">{{ $t('Button.Search') }}</a-button>
            <a-button type="secondary" class="me-2" @click="clear">{{ $t('Button.Cancel') }}</a-button>
          </div>
        </a-col>
      </a-row>
    </a-card>
    <a-spin :spinning="this.loading" size="large">
      <a-card class="mb-30 shadow">
        <a-row>
          <a-col :xs="{ span: 24 }" :xl="{ span: 15 }" class="pr-10 pl-10 mb-3 my-xl-2">
            <a-dropdown style="width: 300px; display: flex; justify-content: space-between; align-items: center;" v-model="visible">
              <template #overlay>
                <a-menu>
                  <a-menu-item class="all" @change="selectAll">
                    <a-checkbox v-model="allSelected" value="all">{{ $t('Transaction.SelectAll') }}</a-checkbox>
                  </a-menu-item>
                  <a-menu-item :key="id" v-for="(column, id) in VAConstant.VA_COLUMN" :class="column.class" @change="handleChooseColumn(column.class)">
                    <a-checkbox :checked="selected.includes(column.class)" :value="column.class">{{ $t(column.title) }}</a-checkbox>
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button @click="handleOpenMenu">
                {{ $t('Transaction.ChooseColumn') }} ({{ selected.length }})
                <i class="bi bi-chevron-down ms-auto"></i>
              </a-button>
            </a-dropdown>
          </a-col>
        </a-row>

        <a-table
            :data-source="qrList"
            :columns="columns"
            :row-key="record => record.id"
            :pagination="false"
            class="mb-30 nowrap"
            :scroll="{x: 'auto'}"
        >
          <template #customTitleNo>
            <div class="no">
              <p align="center" class="mb-0">{{ $t('VirtualAccount.No') }}</p>
            </div>
          </template>
          <template slot="no" slot-scope="text, record, index">
            <p style="font-weight: bold" class="mb-0">{{ (searchInput.page - 1) * perPage + index + 1 }}</p>
          </template>

          <template slot="created_at" slot-scope="text, record">
            <p align="center" class="mb-0">{{ showFormatDate(record.created_at, "HH:mm:ss DD/MM/YYYY") }}</p>
          </template>

          <template #customTitleBranchName>
            <div class="branch_name">
              <p align="center" class="mb-0">{{ $t('VirtualAccount.BranchName') }}</p>
            </div>
          </template>
          <template slot="branch_name" slot-scope="text, record">
            <p align="center" class="mb-0" style="white-space: nowrap">{{ record.branch_name }}</p>
          </template>

          <template #customTitleBankCode>
            <p align="center" class="mb-0">{{ $t('VirtualAccount.Bank') }}</p>
          </template>
          <template slot="bank_code" slot-scope="text, record">
            <p align="center" class="mb-0" style="white-space: nowrap">
              {{ record.bank_code }}
            </p>
          </template>

          <template #customTitleBankAccountNo>
            <p align="center" class="mb-0">{{ $t('VirtualAccount.BankAccountNo') }}</p>
          </template>
          <template slot="bank_account_no" slot-scope="text, record">
            <p align="center" class="mb-0" style="white-space: nowrap">
              {{ record.bank_account_no }}
            </p>
          </template>

          <template #customTitleRequestAmount>
            <p align="center" class="mb-0">{{ $t('VirtualAccount.RequestAmount') }}</p>
          </template>
          <template slot="request_amount" slot-scope="text, record">
            <p align="center" class="mb-0" style="white-space: nowrap">
              {{ record.request_amount > 0 ? showMoneyFormat(record.request_amount) + ' VND' : $t('VirtualAccount.EveryValue') }}
            </p>
          </template>

          <template #customTitleBankAccountName>
            <p align="center" class="mb-0">{{ $t('VirtualAccount.BankAccountName') }}</p>
          </template>
          <template slot="bank_account_name" slot-scope="text, record">
            <p align="center" class="mb-0" style="white-space: nowrap; word-break: break-word">
              {{ record.bank_account_name }}
            </p>
          </template>

          <template #customTitleStatus>
            <p align="center" class="mb-0">{{ $t('VirtualAccount.Status') }}</p>
          </template>
          <template slot="status" slot-scope="text, record">
            <div :class="'text-center fw-600 ' + (record.status == 1 ? 'c_text-success' : 'text-danger')">
              {{ $t(showVAStatus(record.status)) }}
            </div>
          </template>

          <template #customTitleUpdatedAt>
            <div class="updated-time">
              <p align="center" class="mb-0">{{ $t('Transaction.UpdatedAt') }}</p>
            </div>
          </template>
          <template slot="updated_at" slot-scope="text, record">
            <p align="center" class="mb-0">{{ showFormatDate(record.updated_at, "HH:mm:ss DD/MM/YYYY") }}</p>
          </template>
          <template #customTitleViewQRCode>
            <div class="qr-view-code">
              <p align="center" class="mb-0">{{ $t('VirtualAccount.QRCode') }}</p>
            </div>
          </template>
           <template slot="qr-view-code" slot-scope="text, record">
            <a-button type="primary" class="me-2" href="javascript:void(0);" @click="showQr(record)" v-if="isset(record.qr_code)">{{ $t('VirtualAccount.ViewQRCode') }}</a-button>           
          </template>

          <template #customTitleAction >
            <p align="center" class="mb-0">{{ $t('VirtualAccount.Action') }}</p>
          </template>
          <template slot="action" slot-scope="text, record" >
            <div align="center">
              <a-tooltip v-if="(checkPermission(user, 'canViewListTransactionsQR') || checkIsMasterMerchant(user))" class="me-2">
                <template slot="title">
                  <span> {{ $t('VirtualAccount.ViewHistoryTrans') }}</span>
                </template>
                <a href="javascript:void(0);" @click="redirectListTrans(record)">
                  <a-icon slot="prefix" type="eye"/>
                </a>
              </a-tooltip>
              <a-tooltip v-if="checkPermission(user, 'canEditQR') && isMerchant">
                <template slot="title">
                  <span> {{ $t('Tooltip.Edit') }}</span>
                </template>
                <a href="javascript:void(0);" @click="showModalEdit(record)">
                  <a-icon slot="prefix" type="form"/>
                </a>
              </a-tooltip>
            </div>
          </template>
        </a-table>

        <a-row>
          <a-col :span="8">
            {{ $t('ShowPaginateFooterRecords', {'total_row': getCurrentTotalRow(totalRow, perPage, searchInput.page), 'total': totalRow}) }}
          </a-col>
          <a-col :span="16" align="end">
            <a-pagination
                v-model="searchInput.page"
                :current="searchInput.page"
                :page-size="perPage"
                :total="totalRow"
            />
          </a-col>
        </a-row>
      </a-card>
    </a-spin>

    <createQR
        :clientId="dataVaForm.clientId"
        :branchName="dataVaForm.branchName"
        :bankCode="dataVaForm.bankCode"
        :bankAccountName="dataVaForm.bankAccountName"
        :requestAmount="dataVaForm.requestAmount"
        :status="dataVaForm.status"
        :vaStatus="vaStatus"
        :bankAccountNo="dataVaForm.bankAccountNo"
        :visibleModal.sync="visibleModal"
        :isRequestAmount="isRequestAmount"
        :isEdit="isEdit"
        :banks="banks"
        @close-modal="handleModalClose"
        @update-dataVaForm="handleEmitDataVaForm"
    ></createQR>
    <showQR
        :contentQR="qrData.contentQR"
        :bank="qrData.bank"
        :bankAccountName="qrData.bankAccountName"
        :bankAccountNo="qrData.bankAccountNo"
        :bankImg="qrData.bankImg"
        :loading="loading"
        :visibleQrModal="visibleQrModal"
        @close-qr-modal="handleShowQrModalClose"
    ></showQR>
  </AppMasterVue>
</template>

<script>
import AppMasterVue from "@/layout/AppMaster.vue";
import PageTitle from "@/components/PageTitle";
import createQR from "@/components/pages/QR/createQR";
import showQR from "@/components/pages/QR/showQR";
import moment from "moment/moment";
// import DateRangePicker from '@/components/DateRangePicker'
import {mapGetters} from "vuex";
import helper from "@/until/helper";
import VAConstant from "../../../constants/VirtualAccountsStatus";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
// import QrcodeVue from 'qrcode.vue';

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: "QRList",
  components: {
    AppMasterVue,
    PageTitle,
    createQR,
    showQR,
    // DateRangePicker,
  },
  data(){
    return {
      loading: false,
      title: 'Sidebar.QRList',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Sidebar.QRManagement',
          routeName: null,
          active: false,
        },
        {
          name: 'Sidebar.QRList',
          routeName: 'QRList',
          active: true
        }
      ],
      VAConstant,
      visible: false,
      visibleModal: false,
      visibleQrModal: false,
      searchInput: {
        page: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
        // date_from: this.isset(this.$route.query.date_from) ? this.$route.query.date_from : moment().subtract(29, 'days').format("DD/MM/YYYY"),
        // date_to: this.isset(this.$route.query.date_to) ? this.$route.query.date_to : moment().format("DD/MM/YYYY"),
        client_id: this.isset(this.$route.query.client_id) ? this.$route.query.client_id : "",
        bank_account_no: this.isset(this.$route.query.bank_account_no) ? this.$route.query.bank_account_no : "",
        bank_account_name: this.isset(this.$route.query.bank_account_name) ? this.$route.query.bank_account_name : "",
        bank_code: this.isset(this.$route.query.bank_code) ? this.$route.query.bank_code : "",
        status: this.isset(this.$route.query.status) && this.$route.query.status != '' ? parseInt(this.$route.query.status) : 1,
        merchant_id: this.isset(this.$route.query.merchant_id) ? this.$route.query.merchant_id : [],
        virtual_account_id : '',
      },
      columns: [
        {
          key: "no",
          slots: { title: "customTitleNo" },
          scopedSlots: { customRender: "no" },
          align: "center",
          display: true,
          class: ''
        },
        {
          key: "branch_name",
          slots: { title: "customTitleBranchName" },
          scopedSlots: { customRender: "branch_name" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "bank_code",
          slots: { title: "customTitleBankCode" },
          scopedSlots: { customRender: "bank_code" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "bank_account_no",
          slots: { title: "customTitleBankAccountNo" },
          scopedSlots: { customRender: "bank_account_no" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "bank_account_name",
          slots: { title: "customTitleBankAccountName" },
          scopedSlots: { customRender: "bank_account_name" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "request_amount",
          slots: { title: "customTitleRequestAmount" },
          scopedSlots: { customRender: "request_amount" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "status",
          slots: { title: "customTitleStatus" },
          scopedSlots: { customRender: "status" },
          display: false,
          align: "center",
          class: 'hide'
        },
        {
          key: "updated_at",
          slots: { title: "customTitleUpdatedAt" },
          scopedSlots: { customRender: "updated_at" },
          display: false,
          align: "center",
          class: 'hide'
        },
        {
          key: "qr-view-code",
          slots: { title: "customTitleViewQRCode" },
          scopedSlots: { customRender: "qr-view-code" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "action",
          slots: { title: "customTitleAction" },
          scopedSlots: { customRender: "action" },
        },
      ],
      selected: ['qr-view-code', 'branch_name', 'bank_code', 'bank_account_no', 'bank_account_name', 'request_amount'],
      allSelected: false,
      currencyMask,
      isRequestAmount: false,
      isEdit: false,
      maxReqAmount: 500000000,
      dataVaForm: {
        bankCode: '',
        clientId: '',
        branchName: '',
        bankAccountName: '',
        requestAmount: '',
        status: 0
      },
      qrData: {
        contentQR: '',
        bank: '',
        bankAccountName: '',
        bankAccountNo: '',
        bankImg: '',
      },
      vaStatus: false,
      isMerchant: false,
      list_virtual_account: [],
    };
  },
  computed:{
    ...mapGetters({
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants',
      lang: 'authentications/getLang',
      qrList: "qr/qrList",
      totalRow: "qr/getTotalRow",
      perPage: "qr/getPerPage",
      banks: "qr/listBank",
      bankForFilter: 'qr/listBankForFilter',
      profileMerchant: "authentications/getProfileMerchant",
    }),
  },
  mounted(){
    if (isNaN(this.merchants)) {
      this.searchInput.merchant_id = [];
      Object.keys(this.merchants).forEach(merchantId => {
        this.searchInput.merchant_id.push(parseInt(merchantId));
      });
    } else {
      this.searchInput.merchant_id = parseInt(this.merchants);
    }

    if (this.isset(this.user.profile.user_type) && this.user.profile.user_type === 2){
      this.isMerchant = true;
    }
    this.searchInput.virtual_account_id = Number(this.$route.query.virtual_account_id) ? Number(this.$route.query.virtual_account_id) : ''
    this.callApiVAList();
    this.callApiVirtualAccount();
    this.callApiGetBankNines();
  },
  methods:{
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    checkPermission: helper.checkPermission,
    isset: helper.isset,
    getAllVAStatus: helper.getAllVAStatus,
    showVAStatusListHTML: helper.showVAStatusListHTML,
    showVAStatus: helper.showVAStatus,
    getCurrentTotalRow: helper.getCurrentTotalRow,
    showMoneyFormat: helper.showMoneyFormat,
    // onChangeDate(value) {
    //   this.searchInput.date_from = value.dateFrom;
    //   this.searchInput.date_to = value.dateTo;
    // },
    async callApiGetBankNines() {
      this.loading = true;
      let params = {}
      if (isNaN(this.merchants)) {
        params = {
          'merchantId': this.user.profile.id
        }
      }else{
        params = {
          'merchantId': this.searchInput.merchant_id
        }
      }
      await this.$store.dispatch("qr/fetchBankNines", params);
      this.loading = false;
    },
    async callApiVAList() {
      this.loading = true;
      await this.$store.dispatch("qr/fetchVAListForManageModule", this.searchInput);
      this.loading = false;
    },
    showFormatDate(data = null, format = "DD/MM/YYYY HH:mm:ss") {
      if (moment(data).isValid) {
        return moment(data).format(format);
      }
      return moment().format(format);
    },
    setRouter() {
      this.$router.replace({
        name: "QRList",
        query: this.searchInput,
      }).catch(() => {});
    },
    search() {
      this.setRouter();
      this.callApiVAList();
    },
    clear() {
      this.searchInput.page = 1;
      // this.searchInput.date_from = moment().subtract(29, 'days').format("DD/MM/YYYY");
      // this.searchInput.date_to = moment().format("DD/MM/YYYY");
      this.searchInput.client_id = "";
      this.searchInput.bank_account_no = "";
      this.searchInput.bank_account_name = "";
      this.searchInput.bank_code = "";
      this.searchInput.status = "";
      this.searchInput.merchant_id = [];
      this.searchInput.virtual_account_id = ''

      if (isNaN(this.merchants)) {
        this.searchInput.merchant_id = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.searchInput.merchant_id.push(parseInt(merchantId));
        });
      } else {
        this.searchInput.merchant_id = parseInt(this.merchants);
      }

      this.search();
    },
    handleOpenMenu: function() {
      this.visible = true;
    },
    selectAll: function(event) {
      let classes = [];
      if(event.target.checked) {

        Object.values(this.VAConstant.VA_COLUMN).forEach(vCol => {
          classes.push(vCol.class);
        });
      }
      this.selected = classes;
    },
    handleChooseColumn: function(valueCheck) {
      if(this.selected.includes(valueCheck)) {
        let arr = this.selected.filter(function(item) {
          return item !== valueCheck;
        })
        this.selected = arr;
      } else {
        this.selected.push(valueCheck);
      }
      this.allSelected = false;
    },
    showModalCreate() {
      this.resetDataVaForm();
      this.isEdit = false;
      // this.dataVaForm.bankCode = 'TCB';
      this.visibleModal = true;
    },
    showModalEdit(data){
      this.isEdit = true;
      this.dataVaForm.bankCode = data.bank_code;
      this.dataVaForm.clientId = data.client_id;
      this.dataVaForm.branchName = data.branch_name;
      this.dataVaForm.bankAccountName = data.bank_account_name ?? '';
      this.dataVaForm.bankAccountNo = data.bank_account_no;
      this.dataVaForm.requestAmount = data.request_amount;
      this.dataVaForm.status = data.status;
      this.vaStatus = false;
      if (data.status === 1){
        this.vaStatus = true;
      }
      if(data.request_amount !== 0){
        this.isRequestAmount = true;
      }else{
        this.isRequestAmount = false;
      }
      this.visibleModal = true;
    },
    resetDataVaForm(){
      this.dataVaForm.bankCode = '';
      this.dataVaForm.clientId = '';
      this.dataVaForm.branchName = '';
      this.dataVaForm.bankAccountName = '';
      this.dataVaForm.bankAccountNo = '';
      this.dataVaForm.requestAmount = '';
      this.dataVaForm.status = 0;
      this.vaStatus = false;
      this.isRequestAmount = false;
    },
    handleEmitDataVaForm(newData) {
      if (Object.keys(newData).length > 0){
        this.dataVaForm.bankCode = newData.bankCode;
        this.dataVaForm.clientId = newData.clientId;
        this.dataVaForm.branchName = newData.branchName;
        this.dataVaForm.bankAccountName = newData.bankAccountName;
        this.dataVaForm.requestAmount = newData.requestAmount;
        this.dataVaForm.status = newData.status
      }else{
        this.$notification['error']({
          message: this.$t('Notice.Title.Notice'),
          description: this.$t('Notice.Message.CreateQRFail'),
          duration: 5,
          style: {
            "margin-top": "50px"
          },
        });
        return ;
      }
      if (isNaN(this.merchants)){
        this.dataVaForm.merchantId = this.user.profile.id;
      }else{
        this.dataVaForm.merchantId = this.merchants;
      }
      this.dataVaForm.bankAccountName = this.dataVaForm.bankAccountName.toUpperCase()
      if(this.dataVaForm.requestAmount !== '' && this.dataVaForm.requestAmount !== 0 && this.isset(this.dataVaForm.requestAmount)){
        this.dataVaForm.requestAmount = this.dataVaForm.requestAmount.replaceAll(',', '')
      }else{
        this.dataVaForm.requestAmount = '0'
      }
      if (this.loading) {
        return false;
      }
      this.loading = true;
      this.visibleModal = false;
      if(!this.isEdit){
        this.$store.dispatch("qr/createVA", this.dataVaForm).then(response => {
          if(this.isset(response.data) && response.data.data && response.data.code === 200){
            this.$notification['success']({
              message: this.$t('Notice.Title.Notice'),
              description: this.$t('Notice.Message.CreateQRSuccess'),
              duration: 5,
              style: {
                "margin-top": "50px"
              },
            });
            this.qrData.bank = this.banks[response.data.data.bank_code] ?? '';
            this.qrData.bankAccountNo = response.data.data.bank_account_no;
            this.qrData.bankAccountName = response.data.data.bank_account_name;
            this.qrData.contentQR = response.data.data.qr_code ?? ''
            this.qrData.bankImg = this.handleBankImg(response.data.data.bank_code);
            this.visibleQrModal = true;
            this.callApiVAList();
          }
          else if(response.code === 1 && response.errors.error_code === '001'){
            this.$notification['error']({
              message: this.$t('Notice.Title.Notice'),
              description: this.$t('Notice.Message.QRExist'),
              duration: 5,
              style: {
                "margin-top": "50px"
              },
            });
            this.loading = false;
          }
          else{
            this.$notification['error']({
              message: this.$t('Notice.Title.Notice'),
              description: this.$t('Notice.Message.CreateQRFail'),
              duration: 5,
              style: {
                "margin-top": "50px"
              },
            });
            this.loading = false;
          }
        })
      }
      else{
        this.$store.dispatch("qr/updateVa", this.dataVaForm).then(response => {
          if(this.isset(response.data) && response.data.data && response.data.code === 200){
            this.$notification['success']({
              message: this.$t('Notice.Title.Notice'),
              description: this.$t('Notice.Message.UpdateQRSuccess'),
              duration: 5,
              style: {
                "margin-top": "50px"
              },
            });
            this.callApiVAList();
          }
          else if(response.code === 1 && response.errors.error_code === '005'){
            this.$notification['error']({
              message: this.$t('Notice.Title.Notice'),
              description: this.$t('Notice.Message.QRNotExist'),
              duration: 5,
              style: {
                "margin-top": "50px"
              },
            });
            this.loading = false;
          }
          else{
            this.$notification['error']({
              message: this.$t('Notice.Title.Notice'),
              description: this.$t('Notice.Message.UpdateQRFail'),
              duration: 5,
              style: {
                "margin-top": "50px"
              },
            });
            this.loading = false;
          }
        });
      }
      this.resetDataVaForm();
    },
    showQr(data){
      this.qrData.bank = this.banks[data.bank_code] ?? '';
      this.qrData.bankAccountNo = data.bank_account_no;
      this.qrData.bankAccountName = data.bank_account_name;
      this.qrData.contentQR = data.qr_code ?? ''
      this.qrData.bankImg = this.handleBankImg(data.bank_code);
      this.visibleQrModal = true;
    },
    handleBankImg(bankCode) {
      switch (bankCode) {
        case 'BIDV':
          return '/assets/img/BIDV_logo.svg'
        case 'VPB':
          return '/assets/img/VP_logo.svg'
        case 'VCCB':
          return '/assets/img/BanViet_logo.svg'
        case 'TCB':
          return '/assets/img/TCB_logo.svg'
        case 'MSB':
          return '/assets/img/MSB_logo.svg'
        default:
          console.log('Không có bank tương ứng');
          return '';
      }
    },
    handleShowQrModalClose(){
      this.visibleQrModal = false;
      this.qrData.bank = '';
      this.qrData.bankAccountNo = '';
      this.qrData.bankAccountName = '';
      this.qrData.contentQR = ''
      this.qrData.bankImg = '';
    },
    selectMerchant(e) {
      if (e) {
        this.searchInput.merchant_id = parseInt(e);
      } else {
        this.searchInput.merchant_id = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.searchInput.merchant_id.push(parseInt(merchantId));
        });
      }
    },
    redirectListTrans(data){
      this.$router.push({name: 'QRTransactions', query: { virtual_account_id: data.id } });
    },
    handleModalClose(){
      this.visibleModal = false
      this.resetDataVaForm()
    },
    async callApiVirtualAccount() {
      var searchInput = {
        merchant_id: this.searchInput.merchant_id,
      };
      await this.$store.dispatch("virtualUser/fetchVirtualAccountListAll", searchInput).then(response => {
        if(response.data){
          this.list_virtual_account = response.data
        }else{
          alert('Có lỗi xảy ra!')
        }
      });
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  watch:{
    'searchInput.page': {
      handler: function (value) {
        this.searchInput.page = value
        this.setRouter();
        this.callApiVAList();
      },
    },
    selected: {
      handler: function (value) {
        if(value.length == 8) {
          this.allSelected = true;
        }
        let columnHide = [];
        Object.values(this.columns).forEach(vCol => {

          if(value.includes(vCol.key)) {
            vCol.class = '';
          } else {
            columnHide.push(vCol.key);

            Object.values(this.VAConstant.VA_COLUMN).forEach(vTrans => {
              if(vCol.key === vTrans.class) {
                vCol.class = 'hide';
              }
            });
          }
        });
      }
    }
  }
}
</script>

<style type="text/css">
  .ant-modal-body .ant-form-item-control{
    line-height: unset;
  }
  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
  }
  .ant-select-disabled{
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
  }
  .ant-select-disabled .ant-select-selection {
    background: #f5f5f5 !important;
  }
  #printQR{
    background-color: #fff;
    padding: 15px 0;
  }
  .qrcode{
    border: 2px solid transparent;
    padding: 18px;
    width: 75%;
    margin: 0 auto;
    border-radius: 10px;
    background:
        linear-gradient(to right, white, white),
        linear-gradient(126.75deg, #03B84B 5.16%, #06B3A8 68.47%),
        linear-gradient(180deg, #E8FFFE 0%, #BCEBFA 100%);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    display: inline-block;
    font-size: 0;
    position: relative;
  }

  .qrcode .qr-header{
    position: absolute;
    top: -5%;
    left: 18%;
    padding: 5px 8px;
    background-color: #FFFFFF;
    width: 65%;
  }

  .qrcode img{
    width: 100%;
  }

  .qrcode .qr-header h2{
    color: rgba(29, 63, 113, 1);
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
  }

  @media only screen and (max-width: 575px) and (min-width: 481px){
    .qrcode .qr-header h2{
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 499px) and (min-width: 481px){
    .qrcode .qr-header{
      width: 70%;
    }
  }

  @media only screen and (max-width: 480px) and (min-width: 320px){
    .qrcode .qr-header h2{
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 440px) and (min-width: 390px){
    .qrcode .qr-header{
      width: 80%;
      left: 10%;
    }
    .qrcode .qr-header h2{
      line-height: 15px;
    }
  }
  @media only screen and (max-width: 389px) and (min-width: 320px){
    .qrcode .qr-header{
      left: 2%;
      width: 95%;
      top: -7%;
    }
    .qrcode .qr-header h2{
      line-height: 15px;
      font-size: 12px;
    }
  }

  .qrcode .qr-scan-logo{
    position: absolute;
    top: -5%;
    left: 20%;
    width: 60%;
  }

  .qrcode .qrcode__image {
    background-color: #fff;
    border: 0.25rem solid #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
    height: 12%;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 12%;
  }

  .qrcode .qr canvas{
    width: 100% !important;
    height: auto !important;
  }

  .qr-content{
    width: 90%;
    margin: 0 auto;
    margin-top: 8px;
    padding: 0 10px;
  }
  .qr-content .qr-logo{
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 25px;
  }
  .qr-content .qr-logo .logo-item{
    width: 50%;
  }
  .qr-content .qr-logo .logo-hr{
    color: #06B49B;
    opacity: 2;
    width: 1.32px;
    height: 38.15px;
    display: inline-block;
  }
  .qr-content .qr-logo img{
    width: 60%;
    margin: 0 auto;
  }
  .qr-content .qr-account-name{
    color: #1D3F71;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.85px;
    text-align: center;
  }
  .qr-content .qr-account-no{
    color: #1D3F71;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
  }

  .modal-qr .ant-modal {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  .form-item-with-transition {
    animation: fadeInOut 0.5s ease-in-out;
  }
  @keyframes fadeInOut {
    0% { opacity: 0; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  .modal-va .ant-modal-body{
    padding: 10px 24px;
  }
  .modal-va .ant-modal-body .ant-form-item{
    margin-bottom: 10px !important;
    padding: 0 !important;
  }
  .btn-create svg {
    vertical-align: baseline;
  }
  .store-qr-bank-code{
    color: rgb(10 10 10 / 91%) !important;
  }
  .ant-modal-wrap{
    z-index: 1000 !important;
  }
</style>