import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/pages/Home'
import Profile from '@/components/pages/Profile'
import Login from '@/components/pages/Login'
import ListTransaction from '@/components/pages/ListTransaction'
import TransactionDetail from '@/components/pages/TransactionDetail'
import InvoiceList from '@/components/pages/InvoiceList'
import Refund from '@/components/pages/refund/Refund'
import RefundDetail from '@/components/pages/refund/RefundDetail'
import CreateRefund from '@/components/pages/refund/CreateRefund'
import viewInvoice from '@/components/pages/invoice/ViewInvoice'
import CreateInvoice from '@/components/pages/invoice/invoiceMaster'
import store from '../store'
import UserTypeConstant from "../constants/UserType";
import AppConstant from "../constants/App";
import CallbackLogin from '@/components/pages/CallbackLogin'
import ProfileList from '@/components/pages/Profile/ProfileList'
import CreateProfile from '@/components/pages/Profile/CreateProfile'
import MerchantBalanceLog from '@/components/pages/MerchantBalanceLog'
import SchoolPaymentList from '@/components/pages/paymentSchool/SchoolPaymentList'
import SchoolPaymentDetail from '@/components/pages/paymentSchool/SchoolPaymentDetail'
import Mpos from '@/components/pages/mpos/Mpos'
import TermOfService from '@/components/pages/mpos/TermOfService'
import PageNotFound from '@/components/pages/NotFound'
import PaymentPurpose from '@/components/pages/paymentSchool/PaymentPurpose'
import CreatePaymentPurpose from '@/components/pages/paymentSchool/CreatePaymentPurpose'
import TransactionDisbursementList from '@/components/pages/disbursement/TransactionDisbursementList'
import TransactionDisbursementDetail from '@/components/pages/disbursement/TransactionDisbursementDetail'
import MerchantDisbursementLog from '@/components/pages/disbursement/MerchantDisbursementLog'
import BalanceLogWallet from '@/components/pages/Wallet/BalanceLogWallet'
import OrdersHistoryWallet from '@/components/pages/Wallet/OrdersHistoryWallet'
import MposManagement from '@/components/pages/mpos/MposManagement'
import AccountDisbursementList from '@/components/pages/disbursement/AccountDisbursementList'
import MerchantRequestDisbursement from '@/components/pages/disbursement/MerchantRequestDisbursement'
import RefundInstallmentReqList from "@/components/pages/refundInstallmentRequest/RefundInstallmentReqList";
import QRnotify from "@/components/pages/QR/notify";
import TopUpAvailableBalance from "@/components/pages/rechargeInformation/TopUpAvailableBalance";
import QRList from "@/components/pages/QR/listQR";
import QRTransactions from "@/components/pages/QR/transactions";
import WithdrawBalance from "@/components/pages/Balance/Withdraw";
import QRDashboard from "@/components/pages/QR/dashboard";
import DisbursementByBatchConfirm from '@/components/pages/disbursement/DisbursementByBatchConfirm'
import DisbursementByBatchList from '@/components/pages/disbursement/DisbursementByBatchList'
import DisbursementByBatchDetail from '@/components/pages/disbursement/DisbursementByBatchDetail'
import AccountSecurity from '@/components/pages/Security/AccountSecurity'
import MerchantBalanceUsd from "@/components/pages/disbursement/MerchantBalanceUsd";
import AccountBalanceUsd from "@/components/pages/disbursement/AccountBalanceUsd";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/pages/About.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/balance/withdraw',
    name: 'WithdrawBalance',
    component: WithdrawBalance
  },
  {
    path: '/transactions/view/:order_code',
    name: 'TransactionDetail',
    component: TransactionDetail
  },
  {
    path: '/invoices',
    name: 'InvoiceList',
    component: InvoiceList,
  },
  {
    path: '/invoices/create',
    name: 'CreateInvoice',
    component: CreateInvoice
  },
  {
    path: '/invoices/edit/:id',
    name: 'EditInvoice',
    component: CreateInvoice
  },
  {
    path: '/invoices/view/:id',
    name: 'ViewInvoice',
    component: viewInvoice
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/transactions',
    name: 'ListTransaction',
    component: ListTransaction
  },
  {
    path: '/refund',
    name: 'Refund',
    component: Refund
  },
  {
    path: '/refund/view/:id',
    name: 'RefundDetail',
    component: RefundDetail
  },
  {
    path: '/refund/create',
    name: 'CreateRefund',
    component: CreateRefund
  },
  {
    path: '/refund/update/:paymentNo',
    name: 'UpdateRefund',
    component: CreateRefund
  },
  {
    path: '/callback',
    name: 'CallbackLogin',
    component: CallbackLogin,
  },
  {
    path: '/profile-management',
    name: 'ProfileList',
    component: ProfileList
  },
  {
    path: '/profile-management/create',
    name: 'CreateProfile',
    component: CreateProfile
  },
  {
    path: '/profile-management/edit/:id',
    name: 'EditProfile',
    component: CreateProfile
  },
  {
    path: '/profile-management/view/:id',
    name: 'ViewProfile',
    component: CreateProfile
  },
  {
    path: '/merchant-balance-log',
    name: 'MerchantBalanceLog',
    component: MerchantBalanceLog
  },
  {
    path: '/payment-purpose',
    name: 'PaymentPurpose',
    component: PaymentPurpose
  },
  {
    path: '/payment-purpose/create',
    name: 'CreatePaymentPurpose',
    component: CreatePaymentPurpose
  },
  {
    path: '/payment-purpose/edit/:id',
    name: 'EditPaymentPurpose',
    component: CreatePaymentPurpose
  },
  {
    path: '/order-payment-page',
    name: 'SchoolPaymentList',
    component: SchoolPaymentList
  },
  {
    path: '/order-payment-page/view/:orderCode',
    name: 'SchoolPaymentDetail',
    component: SchoolPaymentDetail
  },
  {
    path: '/disbursement/transactions',
    name: 'TransactionDisbursementList',
    component: TransactionDisbursementList
  },
  {
    path: '/disbursement/transactions/:order_code',
    name: 'TransactionDisbursementDetail',
    component: TransactionDisbursementDetail
  },
  {
    path: '/disbursement/merchant-disbursement-log',
    name: 'MerchantDisbursementLog',
    component: MerchantDisbursementLog
  },
  {
    path: '/disbursement/merchant-usd-log',
    name: 'MerchantUsdLog',
    component: MerchantBalanceUsd
  },
  {
    path: '/disbursement/merchant-usd',
    name: 'MerchantUsdLog',
    component: MerchantBalanceUsd
  },
  {
    path: '/disbursement/merchant-usd-account',
    name: 'MerchantUsdAccount',
    component: AccountBalanceUsd
  },
  {
    path: '/disbursement/account-disbursement-list',
    name: 'AccountDisbursementList',
    component: AccountDisbursementList
  },
  {
    path: '/wallet/balance-log-wallet',
    name: 'BalanceLogWallet',
    component: BalanceLogWallet
  },
  {
    path: '/wallet/orders-history-wallet',
    name: 'OrdersHistoryWallet',
    component: OrdersHistoryWallet
  },
  {
    path: '/:shortName/user',
    name: 'MposUser',
    component: Mpos
  },
  {
    path: '/mpos',
    name: 'MposManagement',
    component: MposManagement
  },
  {
    path: '/termOfService/:shortName',
    name: 'TermOfService',
    component: TermOfService
  },
  {
    path: '/page-not-found',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '/disbursement/request',
    name: 'MerchantRequestDisbursement',
    component: MerchantRequestDisbursement
  },
  {
    path: '/recharge-information/top-up-available-balance',
    name: 'TopUpAvailableBalance',
    component: TopUpAvailableBalance
  },
  {
    //bắt buộc để cuối cùng
    path: '/:shortName',
    name: 'Mpos',
    component: Mpos
  },
  {
    path: '/refund-installment/list',
    name: 'RefundInstallmentReqList',
    component: RefundInstallmentReqList
  },
  {
    path: '/qr-notify/list',
    name: 'QRnotify',
    component: QRnotify
  },
  {
    path: '/QR/list',
    name: 'QRList',
    component: QRList
  },
  {
    path: '/qr/transactions',
    name: 'QRTransactions',
    component: QRTransactions
  },
  {
    path: '/qr/dashboard',
    name: 'QRDashboard',
    component: QRDashboard
  },
  {
    path: '/disbursement/disbursement-by-batch-confirm/:id',
    name: 'DisbursementByBatchConfirm',
    component: DisbursementByBatchConfirm
  },
  {
    path: '/disbursement/disbursement-by-batch-list',
    name: 'DisbursementByBatchList',
    component: DisbursementByBatchList
  },
  {
    path: '/disbursement/disbursement-by-batch-detail/:id',
    name: 'DisbursementByBatchDetail',
    component: DisbursementByBatchDetail
  },
  {
    path: '/account/security',
    name: 'AccountSecurity',
    component: AccountSecurity
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/callback'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  const isMpos = to.name === "Mpos" || to.name === "MposUser" || to.name === "PageNotFound" || to.name === "TermOfService" || to.name === "AccountSecurity";
  if (authRequired && !loggedIn && !isMpos) {
    //lưu lại url trước khi login
    let currentURL = window.location.href;
    sessionStorage.setItem('urlBeforeLogin', currentURL);
    return next('/login');
  }

  var user = store.getters["authentications/getUserLogin"];
  var menu = store.getters["authentications/getMenu"];

  if (user) {
      if (user.type == UserTypeConstant.MASTER_MERCHANT) {
          // if (user.type == UserTypeConstant.MERCHANT && user.profile.merchant_profile_extra.is_sdk_whitelabel)
          next()
      }
      if (user.type == UserTypeConstant.MERCHANT
          && ((to.name == 'TransactionDetail' && !menu.includes('TransactionList'))
              || (to.name == 'InvoiceList' && !menu.includes('InvoiceList'))
              || (to.name == 'CreateInvoice' && !menu.includes('AddInvoice'))
              || (to.name == 'EditInvoice' && !menu.includes('AddInvoice'))
              || (to.name == 'ViewInvoice' && !menu.includes('AddInvoice'))
              || (to.name == 'ListTransaction' && !menu.includes('TransactionList'))
              || (to.name == 'Refund' && !menu.includes('RefundManagement'))
              || (to.name == 'RefundDetail' && !menu.includes('RefundManagement'))
              || (to.name == 'CreateRefund' && !menu.includes('RefundManagement'))
              || (to.name == 'UpdateRefund' && !menu.includes('RefundManagement'))
              || (to.name == 'Profile' && !menu.includes('ProfileInfo'))
              || (to.name == 'ProfileList' && !menu.includes('ProfileManagement'))
              || (to.name == 'ViewProfile' && !menu.includes('ProfileManagement'))
              || (to.name == 'CreateProfile' && !menu.includes('ProfileManagement'))
              || (to.name == 'EditProfile' && !menu.includes('ProfileManagement'))
              || (to.name == 'MerchantBalanceLog' && !menu.includes('StatisticsOfBalanceFluctuations'))
              || (to.name == 'SchoolPaymentList' && !menu.includes('OrderPaymentList'))
              || (to.name == 'SchoolPaymentDetail' && !menu.includes('OrderPaymentList'))
              || (to.name == 'PaymentPurpose' && !menu.includes('PaymentPurpose'))
              || (to.name == 'CreatePaymentPurpose' && !menu.includes('PaymentPurpose'))
              || (to.name == 'TransactionDisbursementList' && !menu.includes('TransactionDisbursement'))
              || (to.name == 'TransactionDisbursementDetail' && !menu.includes('TransactionDisbursement'))
              || (to.name == 'MerchantDisbursementLog' && !menu.includes('StatisticsOfAvailableBalanceFluctuations'))
              || (to.name == 'OrdersHistoryWallet' && !menu.includes('OrdersHistoryOfUser'))
              || (to.name == 'MposManagement' && !menu.includes('MposInformation'))
              || (to.name == 'RefundInstallmentReqList' && !menu.includes('RefundInstallmentRequestManage'))
              || (to.name == 'WithdrawBalance' && !menu.includes('Withdraw'))
              || (to.name == 'QRDashboard' && !menu.includes('QRDashboard'))
              || (to.name == 'QRList' && !menu.includes('QRList'))
              || (to.name == 'QRTransactions' && !menu.includes('QRtransactions'))
              || (to.name == 'QRnotify' && !menu.includes('QRnotify'))
              || (to.name == 'MerchantUsdLog' && !menu.includes('StatisticsOfAvailableBalanceUsd'))
              || (to.name == 'MerchantUsdAccount' && !menu.includes('DetailOfUsdBalanceTopUpAccount'))
            || (to.name == 'AccountSecurity' && !menu.includes('AccountSecurity'))
              || (to.name == 'MerchantRequestDisbursement' && !menu.includes('TransferMoney247'))
              || (to.name == 'DisbursementByBatchList' && !menu.includes('DisbursementByBatchList'))
              || (to.name == 'DisbursementByBatchConfirm' && !menu.includes('DisbursementByBatchList'))
              || (to.name == 'DisbursementByBatchDetail' && !menu.includes('DisbursementByBatchList'))
          )) {
          next({name: 'Home'});
      }
      var permissions = user.permissions;
      // Check permission for sub merchant
      if (user.type == UserTypeConstant.SUB_MERCHANT
          && ((to.name == 'TransactionDetail' && (!menu.includes('TransactionList') || permissions.find(item => item.name == 'canViewDetailTransaction') === undefined))
              || (to.name == 'InvoiceList' && (!menu.includes('InvoiceList') || permissions.find(item => item.name == 'canViewListInvoice') === undefined))
              || (to.name == 'CreateInvoice' && (!menu.includes('AddInvoice') || permissions.find(item => item.name == 'canAddInvoice') === undefined))
              || (to.name == 'EditInvoice' && (!menu.includes('AddInvoice') || permissions.find(item => item.name == 'canEditInvoice') === undefined))
              || (to.name == 'ViewInvoice' && (!menu.includes('AddInvoice') || permissions.find(item => item.name == 'canViewDetailInvoice') === undefined))
              || (to.name == 'ListTransaction' && (!menu.includes('TransactionList') || permissions.find(item => item.name == 'canViewListTransaction') === undefined))
              || (to.name == 'Refund' && (!menu.includes('RefundManagement') || permissions.find(item => item.name == 'canViewListRefund') === undefined))
              || (to.name == 'RefundDetail' && (!menu.includes('RefundManagement') || permissions.find(item => item.name == 'canViewDetailRefund') === undefined))
              || (to.name == 'CreateRefund' && (!menu.includes('RefundManagement') || permissions.find(item => item.name == 'canAddRefund') === undefined))
              || (to.name == 'UpdateRefund' && (!menu.includes('RefundManagement') || permissions.find(item => item.name == 'canAddRefund') === undefined))
              || (to.name == 'Profile' && !menu.includes('ProfileInfo'))
              || (to.name == 'ProfileList' && (!menu.includes('ProfileManagement') || permissions.find(item => item.name == 'canViewListProfile') === undefined))
              || (to.name == 'ViewProfile' && (!menu.includes('ProfileManagement') || permissions.find(item => item.name == 'canViewDetailProfile') === undefined))
              || (to.name == 'CreateProfile' && (!menu.includes('ProfileManagement') || permissions.find(item => item.name == 'canAddProfile') === undefined))
              || (to.name == 'EditProfile' && (!menu.includes('ProfileManagement') || permissions.find(item => item.name == 'canEditProfile') === undefined))
              || (to.name == 'MerchantBalanceLog' && (!menu.includes('StatisticsOfBalanceFluctuations') || permissions.find(item => item.name == 'canViewBalanceFluctuationsStatistics') === undefined))
              || (to.name == 'SchoolPaymentList' && (!menu.includes('OrderPaymentList') || permissions.find(item => item.name == 'canViewOrderPaymentPageList') === undefined))
              || (to.name == 'SchoolPaymentDetail' && (!menu.includes('OrderPaymentList') || permissions.find(item => item.name == 'canViewOrderPaymentPageDetail') === undefined))
              || (to.name == 'PaymentPurpose' && !menu.includes('PaymentPurpose'))
              || (to.name == 'CreatePaymentPurpose' && (!menu.includes('PaymentPurpose') || permissions.find(item => item.name == 'canAddPaymentPurpose') === undefined))
              || (to.name == 'TransactionDisbursementList' && (!menu.includes('TransactionDisbursement') || permissions.find(item => item.name == 'canViewTransactionDisbursementList') === undefined))
              || (to.name == 'TransactionDisbursementDetail' && (!menu.includes('TransactionDisbursement') || permissions.find(item => item.name == 'canViewTransactionDisbursementDetail') === undefined))
              || (to.name == 'MerchantDisbursementLog' && (!menu.includes('StatisticsOfAvailableBalanceFluctuations') || permissions.find(item => item.name == 'canViewMerchantDisbursementLog') === undefined))
              || (to.name == 'MerchantUsdLog' && (!menu.includes('StatisticsOfAvailableBalanceUsd') || permissions.find(item => item.name == 'canViewMerchantUsdLog') === undefined))
              || (to.name == 'MerchantUsdAccount' && (!menu.includes('DetailOfUsdBalanceTopUpAccount') || permissions.find(item => item.name == 'canViewAccountMerchantUsd') === undefined))
              || (to.name == 'BalanceLogWallet' && permissions.find(item => item.name == 'canViewWalletBalanceFluctuate') === undefined)
              || (to.name == 'OrdersHistoryWallet' && (!menu.includes('OrdersHistoryOfUser') || permissions.find(item => item.name == 'canViewUserOrderHistory') === undefined))
              || (to.name == 'MposManagement' && (!menu.includes('MposInformation') || permissions.find(item => item.name == 'canViewPaymentLinkInfo') === undefined))
              || (to.name == 'RefundInstallmentReqList' && (!menu.includes('RefundInstallmentRequestManage') || permissions.find(item => item.name == 'canViewListRefundInstallmentRequest') === undefined))
              || (to.name == 'WithdrawBalance' && (!menu.includes('Withdraw') || permissions.find(item => item.name == 'canWithdrawBalance') === undefined))
              || (to.name == 'QRDashboard' && !menu.includes('QRDashboard'))
              || (to.name == 'QRList' && (!menu.includes('QRList') || permissions.find(item => item.name == 'canViewQRList') === undefined))
              || (to.name == 'QRTransactions' && (!menu.includes('QRtransactions') || permissions.find(item => item.name == 'canViewListTransactionsQR') === undefined))
              || (to.name == 'QRnotify' && (!menu.includes('QRnotify') || permissions.find(item => item.name == 'canViewNotificationQR') === undefined))
        || (to.name == 'AccountSecurity' && !menu.includes('AccountSecurity'))
              || (to.name == 'MerchantRequestDisbursement' && (!menu.includes('TransferMoney247') || permissions.find(item => item.name == 'canAddMerchantRequestDisbursement') === undefined))
              || (to.name == 'DisbursementByBatchList' && (!menu.includes('DisbursementByBatchList') || permissions.find(item => item.name == 'canAddMerchantRequestDisbursement') === undefined))
              || (to.name == 'DisbursementByBatchConfirm' && (!menu.includes('DisbursementByBatchList') || permissions.find(item => item.name == 'canAddMerchantRequestDisbursement') === undefined))
              || (to.name == 'DisbursementByBatchDetail' && (!menu.includes('DisbursementByBatchList') || permissions.find(item => item.name == 'canAddMerchantRequestDisbursement') === undefined))
          )
      ) {
          next({name: 'Home'})
      }

      // Check permission for master merchant and sub master merchant
      if (
          (user.type == UserTypeConstant.MASTER_MERCHANT
              || user.type == UserTypeConstant.SUB_MASTER_MERCHANT
              || (user.type == UserTypeConstant.MERCHANT && user.profile.status != AppConstant.MERCHANT_ACTIVE))
          && (to.name == 'CreateInvoice'
              || to.name == 'CreateRefund'
              || to.name == 'UpdateRefund'
              || to.name == 'CreateProfile'
              || to.name == 'EditProfile'
              || to.name == 'CreatePaymentPurpose'
              || to.name == 'MposManagement'
          )
      ) {
          next({name: 'Home'})
      }

      //Check config cms

      let merchantProfile = user.profile;

      if (user.type == UserTypeConstant.MERCHANT || user.type == UserTypeConstant.SUB_MASTER_MERCHANT) {
          if ((typeof merchantProfile.merchant_profile_extra !== 'undefined' && merchantProfile.merchant_profile_extra.receive_money_type !== 2 || !menu.includes('Withdraw'))
              && to.name == 'WithdrawBalance') {
              next({name: 'Home'})
          }
          if ((merchantProfile.is_disbursement !== 1 || !menu.includes('TransferMoney247')) && to.name == 'MerchantRequestDisbursement') {
              next({name: 'Home'})
          }
          if ((typeof merchantProfile.merchant_profile_extra !== 'undefined' && merchantProfile.merchant_profile_extra.is_disbursement_by_batch !== 1 || merchantProfile.is_disbursement !== 1)
              || !menu.includes('DisbursementByBatchList')) {
              if (to.name == 'DisbursementByBatchList' || to.name == 'DisbursementByBatchConfirm' || to.name == 'DisbursementByBatchDetail')
                  next({name: 'Home'})
              }
          }
      }
      next();
})

export default router
